import React, { Component, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { UploadOutlined, ArrowUpOutlined, CheckOutlined, EditOutlined, PlusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Upload, Typography, Row, Col, Button, Table, Select, DatePicker, Space, Card, Spin, Checkbox, Anchor, Modal, Radio } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteLogEntry from './QuoteLogEntry';
import moment from 'moment';
const { Title, Text } = Typography;
const {Option} = Select;
const { Link } = Anchor;


class NewBusinessUploadReport extends Component {
    constructor(props) {
      super(props);
        this.state = {
           seriousColumnHeaders: [],
           seriousFileData: [],
           newBusinessColumnHeaders: [],
           newBusinessFileData: [],
           firstName: '',
           lastName: '',
           subProducer: '',
           zipCode: '',
           productionDate:'',
           product: '',
           agentNumber: '',
           quotedItemCount: '',
           agencyQuotedItems: 0,
           agencySoldItems: 0,
           agencyCloseRatio: 0.0,
           agencyQuotedPolicies: 0,
           agencySoldPolicies: 0,
           agencyClosedPoliciesRatio: 0,
           agencyQuotedHouseholds: 0,
           agencySoldHouseholds: 0,
           agencyClosedHouseholdsRatio: 0,
           distinctSeriousProducers: [],
           distinctNewBusinessProducers: [],
           distinceProducers: [],
           invalidSeriousFileType: false,
           invalidNewBusinessFileType: false,
           removedColumns: [],
           dataFile: [],
           fileName: '',
           openUpload: false,
           dateTo: '',
           dateFrom: '',
           newBusinessQuoteLog: [],
           showModal: false,
           newBusinessUpload: [],
           selectedNewBusinessUpload: [],
           agent: '',
           selectedMonth: moment().format("MMM YYYY"),
           view: 'all',
           scrollToId: 0,
           selectedMatches: ['Suggested', 'Exact', 'No Matches'],
        }
        this.handleSelectedLeadSourceChange = this.handleSelectedLeadSourceChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragUpdate = this.onDragUpdate.bind(this);
        this.handleUploadSave = this.handleUploadSave.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.handleTableClick = this.handleTableClick.bind(this);
        this.handleEntryVisibleChange = this.handleEntryVisibleChange.bind(this);
        this.handleFileClick = this.handleFileClick.bind(this);
        this.handleNoActionClick = this.handleNoActionClick.bind(this);
        this.handleUncompletedClick = this.handleUncompletedClick.bind(this);
        this.suggestedRef = React.createRef();
        this.noRef = React.createRef();
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleAddProspect = this.handleAddProspect.bind(this);
        this.onViewChange = this.onViewChange.bind(this);
        this.ignoreNoMatch = this.ignoreNoMatch.bind(this);
    }
    
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        //this.props.getAgent(agencyId);
        console.log(this.props.selectedMonth);
        this.props.getNewBusinessUploadFiles(agencyId);
        const dateFrom = moment().startOf('month').format('MM/DD/YYYY');
        const dateTo = moment().endOf('month').format('MM/DD/YYYY');
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo);
        this.props.getQuoteLogNewBusinessUpload(agencyId, dateFrom, dateTo);
        this.props.getSingleAgent(agencyId, userId);
        this.setState({dateFrom: dateFrom, dateTo: dateTo});
    }
    componentDidUpdate(prevProps){
        if(this.props.singleAgent != prevProps.singleAgent)
        {
            const agencyId = localStorage.getItem('agencyId');
            const agent = this.props.singleAgent;
            this.setState({agent: agent});
            if(agent.status == 4){
                this.props.getNewBusinessUploadByMonth(agencyId, this.state.dateFrom, this.state.dateTo, '');
            } else {
                this.props.getNewBusinessUploadByMonth(agencyId, this.state.dateFrom, this.state.dateTo, agent.producerNo);
            }
        }
        if(this.props.quoteLogNewBusinessUploads != prevProps.quoteLogNewBusinessUploads){
            console.log(this.props.quoteLogNewBusinessUploads);
            this.setState({newBusinessQuoteLog: this.props.quoteLogNewBusinessUploads });
            if(this.state.scrollToId > 0)
            {
                console.log(this.state.scrollToId);
                document.querySelector("#div" + this.state.scrollToId).scrollIntoView({ behavior: 'smooth' });
                this.setState({scrollToId: 0});
            }
        }
        if(this.props.newBusinessUpload != prevProps.newBusinessUpload){
            this.setState({newBusinessUpload: this.props.newBusinessUpload })
        }
        
    }
    handleSelectedLeadSourceChange(value){
        const valid = this.state.monthDts != '' && value != '';
        this.setState({selectedLeadSource: value, valid: valid});
    }
    onViewChange(e){
        console.log(e.target.value);
        this.setState({view: e.target.value});
    }
    onMonthChange(date, dateString){
        const agencyId = localStorage.getItem('agencyId');
        var threeMonths = moment(date).subtract(3, 'months').calendar();
        const dateFrom = moment(date).startOf('month').format('MM/DD/YYYY');
        const dateTo = moment(date).endOf('month').format('MM/DD/YYYY');
        if(dateString != ''){
            this.setState({dateFrom: moment(date).startOf('month').format('MM/DD/YYYY'), dateTo: moment(date).endOf('month').format('MM/DD/YYYY'), selectedMonth: date});
        }else{
            this.setState({dateFrom: moment().startOf('month').format('MM/DD/YYYY'), dateTo: moment().endOf('month').format('MM/DD/YYYY')});
        }
        const userId = localStorage.getItem('userId');
        var agent = this.state.agent; //this.props.agent.find(a => a.userId == userId);
        this.props.getQuoteLogReport(agencyId, moment(threeMonths).startOf('month').format('MM/DD/YYYY'), dateTo);
        this.props.getQuoteLogNewBusinessUpload(agencyId, dateFrom, dateTo);
        if(agent.status == 4){
            this.props.getNewBusinessUploadByMonth(agencyId, dateFrom, dateTo, '');
        }else{
            this.props.getNewBusinessUploadByMonth(agencyId, dateFrom, dateTo, agent.producerNo);
        }
    }
    handleEntryVisibleChange = () => {
        const visible = !this.state.visible;
        this.setState({ visible: visible });
        const seed = Math.random();
        if(visible == false){
            console.log("Seed: " + seed);
            console.log("QuoteId: " + this.state.selectedQuoteLog.id);
            this.setState({seed: seed, showModal: true});
        }
    }
    handleUploadSave(){
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        this.props.getQuoteLogReport(agencyId, dateFrom, dateTo, 'All');
        var seriousUploads = [];
        this.state.seriousFileData.map((item) => {
            let seriousUpload = {
                producerNo: item[this.state.subProducer],
                firstName: item[this.state.firstName],
                lastName: item[this.state.lastName],
                zip: item[this.state.zipCode],
                productionDate: item[this.state.productionDate],
                product: item[this.state.product],
                itemCount: item[this.state.quotedItemCount],
                agencyId: agencyId,
                fileName: this.state.fileName
            };
            seriousUploads.push(seriousUpload);
        });
        this.props.createSeriousUpload(seriousUploads, dateFrom, dateTo);
        console.log(seriousUploads);
        this.setState({dataFile: this.state.seriousFileData});
    }
    onDragEnd = ({ source, destination, draggableId }) => {
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null
    
        // If the source and destination columns are the same
        // AND if the index is the same, the item isn't moving
        if (
          source.droppableId === destination.droppableId &&
          destination.index === source.index
        )
          return null
    
        this.setState({droppableBackColor: ""});
        // Set start and end variables
        const start = source.droppableId
        const end = destination.droppableId
        if(start != end){
            console.log(destination.droppableId + " : " + source.droppableId);
            if(destination.droppableId == "headers"){
                this.state.seriousColumnHeaders.splice(destination.index, 0, this.state[source.droppableId]);
                this.setState({[source.droppableId]: ""});
            }else if(source.droppableId == "headers"){
                var previousValue = this.state[destination.droppableId];
                this.setState({[destination.droppableId]: this.state.seriousColumnHeaders[source.index]});
                this.state.seriousColumnHeaders.splice(source.index, 1);
                if(previousValue != ""){
                    this.state.seriousColumnHeaders.splice(source.index, 0, previousValue);
                }
            }
            else{
                this.setState({[destination.droppableId]: this.state[source.droppableId]});
                this.setState({[source.droppableId]: this.state[destination.droppableId]})
            }
        }
        return null
    }
    onDragUpdate = ({ source, destination, draggableId }) => {
        if (destination === undefined || destination === null) return null
        this.setState({droppableBackColor: [destination.droppableId]});
    }
    onUploadClick(){
        const openUpload = this.state.openUpload;
        this.setState({openUpload: !openUpload, refreshReport: false, monthDts: '', selectedLeadSource: ''});
    }
    handleTableClick = (quoteLogId) => {
        console.log(quoteLogId);
        const agencyId = localStorage.getItem('agencyId');
        var quoteLog = this.props.quoteLog.find(q => q.id == quoteLogId);
        console.log(quoteLog);
        if(quoteLog != undefined){
            this.setState({
                selectedQuoteLog: quoteLog,
                visible: true,
                soldAuto: 0
            });
        }
    }
    handleNoActionClick = (quoteLogId) => {
        console.log(quoteLogId);
        const agencyId = localStorage.getItem('agencyId');
        var quoteLog = this.props.quoteLog.find(q => q.id == quoteLogId);
        this.props.verifyQuoteLog(quoteLog);

        var newBusinessQuoteLog = this.state.newBusinessQuoteLog;
        var i = newBusinessQuoteLog.findIndex((obj => obj.id == quoteLogId));
        newBusinessQuoteLog[i].verified = true;
        this.setState({newBusinessQuoteLog: newBusinessQuoteLog});
    }
    handleUncompletedClick = (quoteLogId) => {
        console.log(quoteLogId);
        const agencyId = localStorage.getItem('agencyId');
        var quoteLog = this.props.quoteLog.find(q => q.id == quoteLogId);
        this.props.verifyQuoteLog(quoteLog);

        var newBusinessQuoteLog = this.state.newBusinessQuoteLog;
        var i = newBusinessQuoteLog.findIndex((obj => obj.id == quoteLogId));
        newBusinessQuoteLog[i].verified = false;
        this.setState({newBusinessQuoteLog: newBusinessQuoteLog});
    }
    handleFileClick = (file) => {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getSeriousUpload(agencyId, file);
    }
    handleCloseModal(){
        this.setState({showModal: false, selectedQuoteLog: []});
    }
    handleSubmitModal(){
        if(this.state.selectedQuoteLog.id > 0){
            var quoteLog = this.props.quoteLog.find(q => q.id == this.state.selectedQuoteLog.id);
            this.props.verifyQuoteLog(quoteLog);
            const scrollToId = this.state.selectedQuoteLog.id;
            var newBusinessQuoteLog = this.state.newBusinessQuoteLog;
            var i = newBusinessQuoteLog.findIndex((obj => obj.id == this.state.selectedQuoteLog.id));
            newBusinessQuoteLog[i].verified = true;
            this.setState({newBusinessQuoteLog: newBusinessQuoteLog, selectedQuoteLog: [], showModal: false, scrollToId: scrollToId});
            const agencyId = localStorage.getItem('agencyId');
            this.props.getQuoteLogNewBusinessUpload(agencyId, this.state.dateFrom, this.state.dateTo);
        }else{
            var newBusinessUpload = this.state.newBusinessUpload;
            var updatedNewBusinessUpload = this.state.newBusinessUpload;
            this.state.selectedNewBusinessUpload.map(item => {
                updatedNewBusinessUpload = updatedNewBusinessUpload.map(group => {
                    if (group.id === item.id) {
                        return {
                        ...group,
                        added: true
                        };
                    } else {
                        return group;
                    }
                });
                this.setState({newBusinessUpload: updatedNewBusinessUpload});
            });
            this.setState({selectedQuoteLog: [], showModal: false});
        } 
    }
    handleAddProspect = (newBusinessQuote) => {
        const agencyId = localStorage.getItem('agencyId');
        var dwelling = [
            {
                "id": 1,
                "name": "HOME / TOWNHOME",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 3,
                "name": "CONDO",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 5,
                "name": "RENTER",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 7,
                "name": "MANUFACTURED HOME",
                "cnt": 0,
                "checked": false
            }
        ];
        var specialty = [
            {
                "id": 4,
                "name": "UMBRELLA",
                "cnt": 0,
                "checked": false,
            },
            {
                "id": 5,
                "name": "LPP",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 6,
                "name": "LIFE",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 9,
                "name": "FLOOD",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 8,
                "name": "BOAT",
                "cnt": 0,
                "checked": false,
            },
            {
                "id": 10,
                "name": "MOTOR CLUB",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 11,
                "name": "INVANTAGE",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 12,
                "name": "HURRICANE",
                "cnt": 0,
                "checked": false
            },
            {
                "id": 13,
                "name": "SPECIALTY AUTO (Motorcycle, ATV, Golf Cart)",
                "cnt": 0,
                "checked": false,
            }
        ];
        var autoCnt = 0;
        var commercialAutoCnt = 0;
        var agent = '';
        var customerName = '';
        var zip = '';
        var producerNo = 0;
        var writtenDate = '';
        var issuedDate = '';
        var premium = '';
        newBusinessQuote.map(item => {
            var productName = 
                item.product == 'Homeowners' ? 'HOME / TOWNHOME' 
                : 
                item.product == 'Condominiums' ? 'CONDO'
                :
                item.product == 'Renters' ? 'RENTER'
                :
                item.product == 'Mobilehome' ? 'MANUFACTURED HOME'
                :
                item.product == 'Personal Umbrella' ? 'UMBRELLA'
                :
                item.product == 'Boat Owners' ? 'BOAT'
                :
                item.product == 'Auto - Special' ? 'SPECIALTY AUTO (Motorcycle, ATV, Golf Cart)'
                :
                '';
            if(item.product == 'Standard Auto'){
                autoCnt = item.itemCount
            }
            if(item.product == 'Non - Standard Auto'){
                commercialAutoCnt = item.itemCount
            }
            agent = this.props.agent.find(a => a.producerNo == newBusinessQuote.producerNo);
            customerName = item.customerName;
            zip = item.zip;
            producerNo = item.producerNo;
            writtenDate = item.writtenDate;
            issuedDate = item.issuedDate;
            premium = item.premium;
            const specialtyData = specialty;
            const updatedSpecialtyData = specialtyData.map(group => {
                if (group.name === productName) {
                    return {
                    ...group,
                    checked: true,
                    cnt: item.itemCount
                    };
                } else {
                    return group;
                }
            });
            specialty = updatedSpecialtyData;
            const dwellingData = dwelling;
            const updatedDwellingData = dwellingData.map(group => {
                if (group.name === productName) {
                    return {
                    ...group,
                    checked: true,
                    cnt: item.itemCount
                    };
                } else {
                    return group;
                }
            });
            dwelling = updatedDwellingData;
        });
        
        var selectedQuoteLog = {
            agencyId: agencyId,
            agent: agent.firstName,
            auto: autoCnt,
            dts: issuedDate,
            dwelling: 0,
            firstName: customerName,
            ineligible: "N/A",
            lastName: '',
            leadSource: '',
            notes: "",
            objection: "",
            specialty: 0,
            status: "",
            id: 0,
            zip: zip,
            createdDts: moment().format('MM/DD/YYYY'),
            bundle: false,
            bindDate: writtenDate,
            totalPremium: 0,
            commercialAuto: commercialAutoCnt,
            typeQuoteLogLeadSourceId: 0,
            insuranceScore: 0,
            selfGenerated: false,
            currentCarrier: "",
            insuranceScoreAuto: 0,
            currentCarrierAuto: "",
            producerNo: producerNo,
            verified: false,
            dwelling: dwelling,
            specialty: specialty
        };
        console.log(selectedQuoteLog);
        this.setState({selectedQuoteLog: selectedQuoteLog, visible: true, soldAuto: 0, selectedNewBusinessUpload: newBusinessQuote});
    }
    ignoreNoMatch = (newBusinessQuote) => {
        var newBusinessUpload = this.state.newBusinessUpload;
        var updatedNewBusinessUpload = this.state.newBusinessUpload;
        newBusinessQuote.map(item => {
            updatedNewBusinessUpload = updatedNewBusinessUpload.map(group => {
                if (group.id === item.id) {
                    return {
                    ...group,
                    added: true
                    };
                } else {
                    return group;
                }
            });
            this.setState({newBusinessUpload: updatedNewBusinessUpload});
        });
    }
    render() {
        const filterMatchOptions = ['Suggested', 'Exact', 'No Matches'];
        const allProducers = [...this.state.distinctSeriousProducers, ...this.state.distinctNewBusinessProducers];
        const result = this.props.newBusinessUpload ? this.props.newBusinessUpload.filter(function (a) {
            var key = a.customerName + '|' + a.zip + '|' + a.producerNo;
            if (!this[key]) {
                this[key] = true;
                return true;
            }
        }, Object.create(null)) : []
        console.log(result);
        var quoteLogNewBusinessUploads = this.state.newBusinessQuoteLog;
        var newBusinessUpload = this.state.newBusinessUpload && this.state.newBusinessUpload;
        const uniqueQuoteLog = this.state.newBusinessQuoteLog ? this.state.newBusinessQuoteLog.filter(function (q) {
            var key = q.firstName + '|' + q.lastName + '|' + q.zip + '|' + q.producerNo;
            if (!this[key]) {
                this[key] = true;
                return true;
            }
        }, Object.create(null)) : []
        const onCheckboxChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
            console.log('checked = ', checkedValues);
            this.setState({selectedMatches: checkedValues})
          };
        return (
            <div>
                {!this.props.hideMonthSelection &&
                <div>
                <Row>
                    <Col>
                        <Title level={3}>New Business Verification Report</Title>
                    </Col>
                </Row>
                <Row style={{paddingTop: 5, paddingBottom: 20, border: 'solid 1px #3D76DD', borderRadius: "5px", overflow: "hidden", marginTop: 10, boxShadow: '5px 5px 5px #ccc'}}>
                    <Col md={24} style={{padding: 10}}>
                        <Space>
                            <Text style={{fontWeight: 'bold'}}>Choose the month you would like to work</Text>
                            <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.selectedMonth, "MMM YYYY")} />
                        </Space>
                    </Col>
                    {/* <Col md={6}>
                        {
                            this.props.seriousUploadFiles &&
                            this.props.seriousUploadFiles.map((file) =>
                                <Button type="link" onClick={() => this.handleFileClick(file)}>{file}</Button>
                            )
                        }
                    </Col> */}
                </Row>
                </div>
                }
                {
                    this.props.isLoading || this.props.isNewBusinessLoading ?
                    <div style={{textAlign: 'center'}}> <Spin tip="Processing data.  This may take a few minutes... "><p></p></Spin> </div> :
                    this.props.newBusinessUpload &&
                    this.props.newBusinessUpload.length == 0 ?
                    <div style={{textAlign: 'center', marginTop: 10, fontWeight: 'bold', fontSize: 20}}>
                        {this.state.hideMonthSelection == false ? <Text>No data has been uploaded for the month selected.</Text> : ""}
                    </div>
                    : this.props.newBusinessUpload &&
                    <div>
                        <Row style={{paddingTop: 10}}>
                            <Col span={24}>
                                <Space direction="horizontal">
                                    <Radio.Group buttonStyle="solid" value={this.state.view} onChange={this.onViewChange} style={{ marginBottom: 5, marginTop: 10 }}>
                                        <Radio.Button value="uncompleted">Uncompleted</Radio.Button>
                                        <Radio.Button value="completed">Completed</Radio.Button>
                                        <Radio.Button value="all">All</Radio.Button>
                                    </Radio.Group>
                                    <div style={{marginLeft: 20}}>
                                        <Text style={{fontWeight: 'bold'}}>Filter Matches:</Text><br />
                                        <Checkbox.Group onChange={onCheckboxChange} value={this.state.selectedMatches} options={filterMatchOptions} defaultValue={['Suggested', 'Exact', 'No Matches']} style={{ padding: 10, border: 'solid 1px #3D76DD', borderRadius: "5px", overflow: "hidden"}} />
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col span={24}>
                                <Card title="Matches" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderRadius: "20px",overflow: "hidden"}} ref={this.suggestedRef}>
                                {
                                    result &&
                                    result.map((newBusiness) =>  {
                                    const rows = quoteLogNewBusinessUploads.filter(q => 
                                        (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim() + ' ' + q.lastName.toLowerCase().trim()) && 
                                        //newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&
                                        //q.zip.trim() == newBusiness.zip.trim() &&
                                        newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        ||
                                        // (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                        // newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        // //q.zip.trim() == newBusiness.zip.trim() &&
                                        // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        // ||
                                        // (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                        // !newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        // //q.zip.trim() == newBusiness.zip.trim() &&
                                        // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        // ||
                                        // (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                        // newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        // //q.zip.trim() != newBusiness.zip.trim() &&
                                        // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        // ||
                                        (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim() + ' ' + q.lastName.toLowerCase().trim()) && 
                                        //newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        //q.zip.trim() == newBusiness.zip.trim() &&
                                        !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        ).length;
                                    const quote = quoteLogNewBusinessUploads.find(q => 
                                        (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim() + ' ' + q.lastName.toLowerCase().trim()) && 
                                        //newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&
                                        //q.zip.trim() == newBusiness.zip.trim() &&
                                        newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        ||
                                        // (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                        // newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        // //q.zip.trim() == newBusiness.zip.trim() &&
                                        // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        // ||
                                        // (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                        // !newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        // //q.zip.trim() == newBusiness.zip.trim() &&
                                        // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        // ||
                                        // (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                        // newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        // //q.zip.trim() != newBusiness.zip.trim() &&
                                        // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        // ||
                                        (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim() + ' ' + q.lastName.toLowerCase().trim()) && 
                                        //newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        //q.zip.trim() == newBusiness.zip.trim() &&
                                        !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                        );
                                    const exactQuote = quoteLogNewBusinessUploads.filter(q => 
                                        (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim() + ' ' + q.lastName.toLowerCase().trim()) && 
                                        //newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                        //q.zip.trim() == newBusiness.zip.trim() &&
                                        newBusiness.producerNo.trim().includes(q.producerNo.trim()))).length;
                                        return rows > 0 ?
                                        <div id={"div" + quote.id} style={{paddingBottom: 20, display: quote.verified && this.state.view =='uncompleted' || !quote.verified && this.state.view =='completed' ? 'none' : 'block'}}>
                                            <Card style={{display: (exactQuote > 0 && this.state.selectedMatches.includes('Exact')) || (exactQuote == 0 && this.state.selectedMatches.includes('Suggested')) ? 'block' : 'none', borderRadius: "20px",overflow: "hidden", borderColor: '#3D76DD', boxShadow: '5px 5px 5px #ccc', backgroundColor: quote.verified ? '#E6FFE6' : '#fff'}}>
                                                <Row>
                                                    <Col md={24}>
                                                        <Title level={4}>{exactQuote > 0 ? 'Exact Match' : 'Suggested Match'}</Title>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                    {
                                                        !quote.verified ?
                                                        <Space direction="vertical">
                                                            <Button type="primary" shape="round" size="small" onClick={() => this.handleTableClick(quote.id)}><EditOutlined /> View & Edit</Button>
                                                            {/* <Button type="primary" shape="round" size="small"><ArrowUpOutlined /> Merge Selected</Button> */}
                                                            <Button type="primary" shape="round" size="small" onClick={() => this.handleNoActionClick(quote.id)}><CheckOutlined /> Mark As Completed</Button>
                                                        </Space>
                                                        :
                                                        <Button type="primary" shape="round" size="small" onClick={() => this.handleUncompletedClick(quote.id)}><EditOutlined /> Mark As Uncompleted</Button>
                                                    }
                                                    </Col>
                                                    <Col md={21}>
                                                        <Text style={{fontWeight: 'bold'}}>HUMBLE 100 QUOTE</Text>
                                                        <table style={{width: '100%'}}>
                                                            <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', textAlign: 'left'}}>
                                                                <tr>
                                                                    <th style={{width: '14%'}}>Date</th>
                                                                    <th style={{width: '14%'}}>Sub Producer</th>
                                                                    <th style={{width: '14%'}}>Customer Name</th>
                                                                    <th style={{width: '14%'}}>Zip</th>
                                                                    <th style={{width: '14%'}}>Product</th>
                                                                    <th style={{width: '14%'}}>Item Count</th>
                                                                    <th style={{width: '14%'}}>Premium</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {quoteLogNewBusinessUploads.filter(q => 
                                                                (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim() + ' ' + q.lastName.toLowerCase().trim()) && 
                                                                //newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&
                                                                //q.zip.trim() == newBusiness.zip.trim() &&
                                                                newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                                ||
                                                                // (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                                // newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                                // //q.zip.trim() == newBusiness.zip.trim() &&
                                                                // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                                // ||
                                                                // (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                                // !newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                                // //q.zip.trim() == newBusiness.zip.trim() &&
                                                                // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                                // ||
                                                                // (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                                // newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                                // //q.zip.trim() != newBusiness.zip.trim() &&
                                                                // newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                                // ||
                                                                (newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim() + ' ' + q.lastName.toLowerCase().trim()) && 
                                                                //newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                                //q.zip.trim() == newBusiness.zip.trim() &&
                                                                !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                                ).map((item) =>
                                                                <tr style={{borderBottom: 'solid #ccc 1px'}}>
                                                                    <td>{moment(item.dts).format('MM/DD/YYYY')}</td>
                                                                    <td style={{backgroundColor: !newBusiness.producerNo.trim().includes(item.producerNo.trim()) && '#ffe6ee'}}>{item.producerNo}</td>
                                                                    <td style={{backgroundColor: !newBusiness.customerName.toLowerCase().trim().includes(item.firstName.toLowerCase().trim()) ||  !newBusiness.customerName.toLowerCase().trim().includes(item.lastName.toLowerCase().trim()) && '#ffe6ee'}}>{item.firstName + ' ' + item.lastName}</td>
                                                                    <td>{item.zip}</td>
                                                                    <td>{item.product}</td>
                                                                    <td>{item.itemCount}</td>
                                                                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(item.premium)}</td>
                                                                </tr>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <hr />
                                                        <Text style={{fontWeight: 'bold', selfAlign: 'center'}}>NEW BUSINESS</Text>
                                                        <table style={{width: '100%'}}>
                                                            <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', textAlign: 'left'}}>
                                                                <tr>
                                                                    <th style={{width: '14%'}}>Date</th>
                                                                    <th style={{width: '14%'}}>Sub Producer</th>
                                                                    <th style={{width: '14%'}}>Customer Name</th>
                                                                    <th style={{width: '14%'}}>Zip</th>
                                                                    <th style={{width: '14%'}}>Product</th>
                                                                    <th style={{width: '14%'}}>Item Count</th>
                                                                    <th style={{width: '14%'}}>Premium</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.newBusinessUpload.filter(ser => 
                                                                ser.customerName == newBusiness.customerName && 
                                                                ser.zip == newBusiness.zip &&
                                                                ser.producerNo == newBusiness.producerNo).map((item) =>
                                                                <tr style={{borderBottom: 'solid #ccc 1px'}}>
                                                                    <td>{moment(item.writtenDate).format('MM/DD/YYYY')}</td>
                                                                    <td>{item.producerNo}</td>
                                                                    <td>{item.customerName}</td>
                                                                    <td></td>
                                                                    <td>{item.product}</td>
                                                                    <td>{item.itemCount}</td>
                                                                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(item.premium)}</td>
                                                                </tr>
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    )
                                }
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col span={24}>
                                <Card title={<div>New Business Records <small> With No Matches Found In Humble 100</small></div>} headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderRadius: "20px",overflow: "hidden", display: this.state.view =='completed' || !this.state.selectedMatches.includes('No Matches') ? 'none' : 'block'}} ref={this.noRef}>
                                    {
                                        result &&
                                        result.map((newBusiness) => {
                                            const noOtherMatch = quoteLogNewBusinessUploads.filter(q => 
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                !newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                //q.zip.trim() != newBusiness.zip.trim() &&
                                                !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                ||
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                //q.zip.trim() != newBusiness.zip.trim() &&
                                                !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                ||
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                //q.zip.trim() != newBusiness.zip.trim() &&
                                                !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                ||
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                !newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim())  
                                                //q.zip.trim() != newBusiness.zip.trim())
                                            )).length;
                                            var newBusinessQuote = newBusinessUpload.filter(ser => 
                                                ser.customerName == newBusiness.customerName && 
                                                ser.zip == newBusiness.zip &&
                                                ser.producerNo == newBusiness.producerNo);
                                            return noOtherMatch == quoteLogNewBusinessUploads.length ?
                                            <div style={{paddingBottom: 20, display: newBusinessQuote[0] && newBusinessQuote[0].added ? 'none' : 'block'}}>
                                                <Card style={{borderRadius: "20px",overflow: "hidden", borderColor: '#3D76DD', boxShadow: '5px 5px 5px #ccc'}}>
                                                    <Row>
                                                        <Col md={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        {newBusinessQuote[0] && newBusinessQuote[0].added ?
                                                            <div></div>
                                                            :
                                                            <Space direction="vertical">
                                                                <Button type="primary" shape="round" size="small" onClick={() => this.handleAddProspect(newBusinessQuote)}><PlusCircleOutlined /> Add Quoted Prospect</Button>
                                                                <Button type="primary" shape="round" size="small" onClick={() => this.ignoreNoMatch(newBusinessQuote)}><CloseOutlined /> Reviewed and Ignore</Button>
                                                            </Space>
                                                        }
                                                        </Col>
                                                        <Col md={21}>
                                                            <Text style={{fontWeight: 'bold', selfAlign: 'center'}}>New Business</Text>
                                                            <table style={{width: '100%'}}>
                                                                <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', textAlign: 'left'}}>
                                                                    <tr>
                                                                        <th style={{width: '15%'}}>Written Date</th>
                                                                        <th style={{width: '15%'}}>Sub Producer</th>
                                                                        <th style={{width: '15%'}}>Customer Name</th>
                                                                        <th style={{width: '15%'}}>Zip</th>
                                                                        <th style={{width: '15%'}}>Product</th>
                                                                        <th style={{width: '15%'}}>Item Count</th>
                                                                        <th style={{width: '15%'}}>Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {newBusinessUpload.filter(ser => 
                                                                    ser.customerName == newBusiness.customerName && 
                                                                    ser.zip == newBusiness.zip &&
                                                                    ser.producerNo == newBusiness.producerNo).map((item) =>
                                                                    <tr style={{borderBottom: 'solid #ccc 1px'}}>
                                                                        <td>{moment(item.writtenDate).format('MM/DD/YYYY')}</td>
                                                                        <td>{item.producerNo}</td>
                                                                        <td>{item.customerName}</td>
                                                                        <td>{item.zip}</td>
                                                                        <td>{item.product}</td>
                                                                        <td>{item.itemCount}</td>
                                                                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(item.premium)}</td>
                                                                    </tr>
                                                                    )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                            
                                            :
                                            <div></div>
                                        })
                                    }
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col span={24}>
                                <Card title={<div>Humble 100 Records <small>With No Matches Found In New Business File</small></div> } headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderRadius: "20px",overflow: "hidden", display: this.state.view =='completed' || !this.state.selectedMatches.includes('No Matches') ? 'none' : 'block'}} ref={this.noRef}>
                                    {
                                        uniqueQuoteLog &&
                                        uniqueQuoteLog.filter(b => b.status == "SOLD" & moment(b.bindDate).format("MM/DD/YYYY") > this.state.dateFrom & moment(b.bindDate).format("MM/DD/YYYY") < this.state.dateTo).map((q) => {
                                            const noOtherMatch = result.filter(newBusiness => 
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                !newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                //q.zip.trim() != newBusiness.zip.trim() &&
                                                !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                ||
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim()) &&  
                                                //q.zip.trim() != newBusiness.zip.trim() &&
                                                !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                ||
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                //q.zip.trim() != newBusiness.zip.trim() &&
                                                !newBusiness.producerNo.trim().includes(q.producerNo.trim()))
                                                ||
                                                (!newBusiness.customerName.toLowerCase().trim().includes(q.firstName.toLowerCase().trim()) && 
                                                !newBusiness.customerName.toLowerCase().trim().includes(q.lastName.toLowerCase().trim())  
                                                //q.zip.trim() != newBusiness.zip.trim())
                                            )).length;
                                            var newBusinessQuote = quoteLogNewBusinessUploads.filter(ser => 
                                                ser.firstName == q.firstName && 
                                                ser.lastName == q.lastName && 
                                                ser.zip == q.zip &&
                                                ser.producerNo == q.producerNo);
                                            return noOtherMatch == result.length  ?
                                            <div id={"div" + q.id} style={{paddingBottom: 20, display: q.verified && this.state.view =='uncompleted' || !q.verified && this.state.view =='completed' ? 'none' : 'block'}}>
                                                <Card style={{borderRadius: "20px",overflow: "hidden", borderColor: '#3D76DD', boxShadow: '5px 5px 5px #ccc', backgroundColor: q.verified ? '#E6FFE6' : '#fff'}}>
                                                    <Row>
                                                        <Col md={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                            <Space direction="vertical">
                                                                <Button type="primary" shape="round" size="small" onClick={() => this.handleTableClick(q.id)}><EditOutlined /> View & Edit</Button>
                                                            </Space>
                                                        </Col>
                                                        <Col md={21}>
                                                            
                                                            <table style={{width: '100%'}}>
                                                                <thead style={{fontWeight: 'bold', backgroundColor: '#fafafa', textAlign: 'left'}}>
                                                                    <tr>
                                                                        <th style={{width: '15%'}}>Written Date</th>
                                                                        <th style={{width: '15%'}}>Sub Producer</th>
                                                                        <th style={{width: '15%'}}>Customer Name</th>
                                                                        <th style={{width: '15%'}}>Zip</th>
                                                                        <th style={{width: '15%'}}>Product</th>
                                                                        <th style={{width: '15%'}}>Item Count</th>
                                                                        <th style={{width: '15%'}}>Premium</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {quoteLogNewBusinessUploads.filter(ser => 
                                                                    ser.firstName == q.firstName && 
                                                                    ser.lastName == q.lastName && 
                                                                    ser.zip == q.zip &&
                                                                    ser.producerNo == q.producerNo).map((item) =>
                                                                    <tr style={{borderBottom: 'solid #ccc 1px'}}>
                                                                        <td>{moment(item.bindDate).format('MM/DD/YYYY')}</td>
                                                                        <td>{item.producerNo}</td>
                                                                        <td>{item.firstName + " " + item.lastName}</td>
                                                                        <td>{item.zip}</td>
                                                                        <td>{item.product}</td>
                                                                        <td>{item.itemCount}</td>
                                                                        <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(item.premium)}</td>
                                                                    </tr>
                                                                    )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                            :
                                            <div></div>
                                            
                                        })
                                    }
                                </Card>
                            </Col>
                        </Row>
                        <QuoteLogEntry productFilter="Allstate" hideButton={true} visible={this.state.visible} selectedQuoteLog={this.state.selectedQuoteLog} onVisibleChange={this.handleEntryVisibleChange} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} type={this.state.reportFilter} />
                    </div>
                }
                <Modal
                    title="The Humble 100"
                    visible={this.state.showModal}
                    onOk={this.handleCloseModal}
                    onCancel={this.handleCloseModal}
                    footer={[
                        <Button key="submit" onClick={() => this.handleSubmitModal()}>
                            Yes
                        </Button>,
                        <Button key="close" type="primary" onClick={this.handleCloseModal}>
                            No
                        </Button>,
                    ]}>
                    <p>Mark As Completed?</p>
                </Modal>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(NewBusinessUploadReport);