import React, { Component  } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin, Tooltip } from 'antd';
import { SearchOutlined, DownloadOutlined, CheckOutlined, FunnelPlotOutlined, ArrowLeftOutlined, ArrowRightOutlined, TableOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import ActivityLogReport from './ActivityLogReport';
import ActivityLogReportDetailed from './ActivityLogReportDetailed';
import ActivityLogAgentSummary from './ActivityLogAgentSummary';
import ActivityLogAverage from './ActivityLogAverage';
import Confetti from 'react-confetti';
import ConfettiExplosion from 'react-confetti-explosion';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class ActivityLog extends Component {
    constructor(props) {
      super(props);
        this.state = {
            view: localStorage.getItem('activityLogView') != null ? localStorage.getItem('activityLogView') : "summary",
            trendingView: localStorage.getItem('premiumAverageFilter') != null ? localStorage.getItem('premiumAverageFilter') : "premium"
        }
        this.handleChangeView = this.handleChangeView.bind(this);
        this.handleTrendingChange = this.handleTrendingChange.bind(this);
    }
    
    componentDidMount() {
        
    }
    componentDidUpdate(prevProps){
        
    }
    handleChangeView(view){
        localStorage.setItem('activityLogView', view);
        this.setState({view: view});
    }

    handleTrendingChange(view){
        console.log(view);
        this.setState({ trendingView: view });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col span={24}>
                        <ActivityLogAverage onChangeTrending={this.handleTrendingChange} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ActivityLogAgentSummary trendingView={this.state.trendingView} />
                    </Col>
                </Row>
                {this.state.view == "detailed" ? 
                    <Row style={{backgroundColor: '#F5FBFF'}}>
                        <Col span={24}>
                            <ActivityLogReportDetailed onChangeView={this.handleChangeView} />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col span={24} style={{backgroundColor: '#F5FBFF'}}>
                            <ActivityLogReport onChangeView={this.handleChangeView} />
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ActivityLog);