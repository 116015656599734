import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin, Alert, Drawer } from 'antd';
import { SearchOutlined, SaveOutlined, DeleteOutlined, PlusCircleOutlined, StarFilled, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import SoldQuoteLog from './SoldQuoteLog';
import EMSoldQuoteLog from './EMSoldQuoteLog';
import ActivitySummary from './ActivitySummary';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class ActivityLogEntry extends Component {
    constructor(props) {
      super(props);
        this.state = {
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            status: '',
            originalStatus: '',
            firstName: '',
            lastName: '',
            leadSource: '',
            dwelling: [],
            auto: 0,
            specialty: [],
            objection: 13,
            ineligible: 6,
            notes: '',
            dateFrom: this.props.dateFrom,
            dateTo: this.props.dateTo,
            tablePageSize: 50,
            searchFilter: 'date',
            openSearch: false,
            zip: '',
            invalid: false,
            visible: false,
            selectedDwelling: [],
            selectedSpecialty: [],
            selectedDwellingCnt: [],
            selectedSpecialtyCnt: [],
            dwellingCheckboxes: [],
            dwellingSoldCheckboxes: [],
            specialtyCheckboxes: [],
            specialtySoldCheckboxes: [],
            carrierProductCheckboxes: [],
            emProductSoldCheckboxes: [],
            soldEffectiveDate: moment().format("MM/DD/YYYY"),
            soldBindDate: moment().format("MM/DD/YYYY"),
            soldEMEffectiveDate: moment().format("MM/DD/YYYY"),
            soldEMBindDate: moment().format("MM/DD/YYYY"),
            soldAuto: -1,
            soldCommercialAuto: 0,
            autoPremium: '',
            quoteLogSoldId: 0,
            isSold: false,
            soldBundle: false,
            soldEMBundle: false,
            autoVisible: false,
            commercialAutoVisible: false,
            commercialAuto: 0,
            commercialAutoPremium: '',
            typeVendorTypeId: 0,
            isZipValid: true,
            selfGenerated: false,
            insuranceScore: 0,
            currentCarrier: "",
            insuranceScoreAuto: 0,
            currentCarrierAuto: "",
            showIneligible: false,
            agentId: 0,
            editable: true,
            referringAgentId: 0,
            bundle: false,
            invalidModalVisible: false,
            requote: false,
            productFilter: this.props.productFilter,
            selectedCarrier: '',
            activeAllstateKey: ["0"],
            activeEMKey: ["0"],
            activeMainEMKey: ["0"]
        }
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleLeadSourceChange = this.handleLeadSourceChange.bind(this);
        this.handleObjectionChange = this.handleObjectionChange.bind(this);
        this.handleIneligibleChange = this.handleIneligibleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleActivityLogDelete = this.handleActivityLogDelete.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCheckZipCode = this.handleCheckZipCode.bind(this);
        this.handleInsuranceScoreChange = this.handleInsuranceScoreChange.bind(this);
        this.handleSelfGeneratedChange = this.handleSelfGeneratedChange.bind(this);
        this.handleCurrentCarrierChange = this.handleCurrentCarrierChange.bind(this);
        this.handleInsuranceScoreAutoChange = this.handleInsuranceScoreAutoChange.bind(this);
        this.handleCurrentCarrierAutoChange = this.handleCurrentCarrierAutoChange.bind(this);
        this.handleReferringAgentChange = this.handleReferringAgentChange.bind(this);
        this.handleRequoteChange = this.handleRequoteChange.bind(this);
        this.handleCarrierChange = this.handleCarrierChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleProductNumberChange = this.handleProductNumberChange.bind(this);
        this.handleProductQuoteDateChange = this.handleProductQuoteDateChange.bind(this);
        this.handleProductQuotePremiumChange = this.handleProductQuotePremiumChange.bind(this);
        this.handleProductStatusChange = this.handleProductStatusChange.bind(this);
        this.handleProductObjectionChange = this.handleProductObjectionChange.bind(this);
        this.handleProductIneligibleChange = this.handleProductIneligibleChange.bind(this);
        this.handleProductBundleChange = this.handleProductBundleChange.bind(this);
        this.handleActiveKeyChange = this.handleActiveKeyChange.bind(this);
        this.handleEMActiveKeyChange = this.handleEMActiveKeyChange.bind(this);
    }
    
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getAgent(agencyId);
        this.props.getLeadSource(agencyId);
        this.props.getObjection();
        this.props.getStatus();
        this.props.getIneligible();
        this.props.getReferringAgent(agencyId);
        this.props.getTypeVendorTypes(agencyId);
        this.props.getCarrierProductCheckboxes(agencyId);
        this.props.getCurrentCarriers();
    }
    componentDidUpdate(prevProps){
        if(this.props.visible != prevProps.visible){
            console.log(this.props.productFilter);
            console.log(this.props.selectedActivityLog);
            this.setState({visible: this.props.visible, productFilter: this.props.productFilter})
            if(this.props.selectedActivityLog != undefined && this.props.visible == true){
                var activityLog = this.props.selectedActivityLog
                this.setState({
                    id: activityLog.id,
                    createdDts: moment(activityLog.createdDts).format("MM/DD/YYYY"),
                    agent: activityLog.agent,
                    agentId: activityLog.agentId,
                    firstName: activityLog.firstName,
                    lastName: activityLog.lastName,
                    leadSource: isNaN(activityLog.leadSource) || activityLog.leadSource == '' ? activityLog.leadSource : this.props.leadSource.find(l => l.id == activityLog.leadSource).name,
                    //objection: this.props.objection.find(l => l.name == activityLog.objection).id,
                    //ineligible: this.props.ineligible.find(l => l.name == activityLog.ineligible).id,
                    notes: activityLog.notes,
                    zip: activityLog.zip,
                    typeVendorTypeId: !isNaN(activityLog.leadSource) & activityLog.leadSource != ''  && this.props.leadSource.find(l => l.id == activityLog.typeQuoteLogLeadSourceId).vendorType,
                    selfGenerated: activityLog.selfGenerated,
                    insuranceScore: activityLog.insuranceScore,
                    currentCarrier: activityLog.currentCarrier,
                    insuranceScoreAuto: activityLog.insuranceScoreAuto,
                    currentCarrierAuto: activityLog.currentCarrierAuto,
                    referringAgentId: activityLog.referringAgentId,
                    bundle: activityLog.bundle,
                    requote: activityLog.requote
                });
                if(activityLog.id > 0){
                    this.props.getActivityLogCarrierProductCheckboxes(activityLog.agencyId, activityLog.id);
                }else{
                    //this.setState({dwellingCheckboxes: quoteLog.dwelling, specialtyCheckboxes: quoteLog.specialty});
                }
                
            } else{
                this.setState({agent: this.props.loggedInAgentName});
            }
        }
        if(this.props.carrierProductCheckboxes != prevProps.carrierProductCheckboxes){
            this.setState({carrierProductCheckboxes: this.props.carrierProductCheckboxes});
            console.log(this.props.carrierProductCheckboxes);
        }
        if(this.props.activityLogCarrierProductCheckboxes != prevProps.activityLogCarrierProductCheckboxes){
            var allStateProducts = this.props.activityLogCarrierProductCheckboxes.find(r => r.carrier.inactive == false && r.productCheckboxes.length > 0 && r.carrier.name == "Allstate");
            var activeAllStateKey = allStateProducts.productCheckboxes.filter(c => c.checked).length > 0 ? ["1"] : [];
            var emProducts = this.props.activityLogCarrierProductCheckboxes.find(r => r.carrier.inactive == false && r.productCheckboxes.length > 0 && r.carrier.name != "Allstate");
            var activeEMKey = [];
            if(emProducts != undefined){
                console.log(emProducts.productCheckboxes);
                var checkedProducts = emProducts.productCheckboxes.filter(c => c.checked).map(cp => {
                    activeEMKey.push(cp.carrierId);
                });
            }
            console.log(activeEMKey);
            this.setState({carrierProductCheckboxes: this.props.activityLogCarrierProductCheckboxes, activeAllstateKey: activeAllStateKey, activeEMKey: activeEMKey, activeMainEMKey: activeEMKey==[] ? ["0"] : ["1"]});
            
        }
    }
    handleStateChange = (e) => {
        this.setState({state: e});
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleAgentChange = (e, option) => {
        this.setState({agent: e, agentId: option.key});
    }
    handleReferringAgentChange = (e) => {
        console.log(e);
        this.setState({referringAgentId: e});
    }
    handleLeadSourceChange(id) {
        console.log(id);
        var leadSource = this.props.leadSource.filter(l => l.id == id);
        console.log(leadSource);
        this.setState({leadSource: leadSource[0].name, typeVendorTypeId: leadSource[0].vendorType, bundle: leadSource[0].autoBundle, soldBundle: leadSource[0].autoBundle});
    }
    handleObjectionChange = (e) => {
        console.log(e);
        this.setState({objection: e});
        if(e == "9" || e == "11" || e == "12"){
            this.setState({showIneligible: true, ineligible: 1});
        }
        else{
            this.setState({showIneligible: false, ineligible: 6});
        }
    }
    handleIneligibleChange = (e) => {
        this.setState({ineligible: e});
    }
    clearProductCheckboxes = () => {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getCarrierProductCheckboxes(agencyId);
    }
    handleProductChange = (e) => {
        console.log(e);
        const { value, checked } = e.target;
        const data = this.state.carrierProductCheckboxes;
        value.checked = value.checked === checked ? null : checked;
        if(checked == true){
            value.cnt = 1;
        } else {
            value.cnt = 0;
            value.quoteDate = moment().format('MM/DD/YYYY');
            value.quotePremium = 0;
            value.status = "";
            value.bindDate = moment().format('MM/DD/YYYY');
            value.writtenCnt = 0;
            value.writtenPremium = 0;
            value.effectiveDate = moment().format('MM/DD/YYYY');
            value.typeQuoteLogObjectionId = 0;
            value.typeQuoteLogIneligibleId = 0;
            value.bundle = false;
        }
        
        const updatedData = data.map(group => {
            if (group.carrier.id === value.carrierId && group.productCheckboxes.productId === value.productId) {
                return {
                ...group,
                productCheckboxes: value
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductQuoteDateChange = (product, quoteDate) => {
        console.log(moment(quoteDate).format("MM/DD/YYYY"));
        const data = this.state.carrierProductCheckboxes;
        product.quoteDate = moment(quoteDate).format("MM/DD/YYYY");
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductNumberChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.cnt = e;
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductQuotePremiumChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.quotePremium = e.target.value;
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductStatusChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.status = e;
        product.writtenCnt = e == "SOLD" ? product.cnt : 0;
        product.writtenPremium = e == "SOLD" ? product.quotePremium : 0;
        
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductBindDateChange = (product, bindDate) => {
        const data = this.state.carrierProductCheckboxes;
        product.bindDate = moment(bindDate).format("MM/DD/YYYY");
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductWrittenNumberChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.writtenCnt = e;
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductWrittenPremiumChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.writtenPremium = e.target.value;
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductEffectiveDateChange = (product, effectiveDate) => {
        const data = this.state.carrierProductCheckboxes;
        product.effectiveDate = moment(effectiveDate).format("MM/DD/YYYY");
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductObjectionChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.typeQuoteLogObjectionId = e;
        
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductIneligibleChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.typeQuoteLogIneligibleId = e;
        
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleProductBundleChange = (e) => {
        console.log(e);
        const { value, checked } = e.target;
        const data = this.state.carrierProductCheckboxes;
        value.bundle = checked;

        const updatedData = data.map(group => {
            if (group.carrier.id === value.carrierId && group.productCheckboxes.productId === value.productId) {
                return {
                ...group,
                productCheckboxes: value
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleSave(event) {
        event.preventDefault();
        const id = this.state.id;
        const agent = this.state.agent;
        const agentId = this.state.agentId;
        const firstName = this.state.firstName;
        const lastName = this.state.lastName;
        const leadSource = this.state.leadSource;
        const objection = this.state.objection;
        const ineligible = this.state.ineligible;
        const notes = this.state.notes;
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.props.dateFrom;
        const dateTo = this.props.dateTo;
        const zip = this.state.zip;
        const typeQuoteLogLeadSourceId = this.props.leadSource.find(l => l.name == leadSource).id;
        const insuranceScore = 0; //this.state.insuranceScore;
        const selfGenerated = this.state.selfGenerated;
        const currentCarrier = this.state.currentCarrier;
        const insuranceScoreAuto = 0; //this.state.insuranceScoreAuto;
        const currentCarrierAuto = this.state.currentCarrierAuto;
        const referringAgentId = this.state.referringAgentId;
        const productCheckboxes = this.state.carrierProductCheckboxes;
        const view = this.props.view;
        const type = this.props.type;
        var bundle = this.state.bundle;
        const requote = this.state.requote;
        var invalid = false;

        if(productCheckboxes.length > 1){
            bundle = 1;
        }

        if(!this.isQuoteLogValid()){
            invalid = true;
            this.setState({invalid: true, invalidModalVisible: true});
        }
        else{
            this.props.createActivityLog(id, agent, firstName, lastName, leadSource, objection, ineligible, notes, agencyId, dateFrom, dateTo, zip, bundle, type, typeQuoteLogLeadSourceId, insuranceScore, selfGenerated, currentCarrier, insuranceScoreAuto, currentCarrierAuto, agentId, referringAgentId, requote, productCheckboxes, view);
        }

        if(!invalid){
            this.setState({
                id: 0,
                date: moment().format("MM/DD/YYYY"),
                agent: '',
                agentId: 0,
                firstName: '',
                lastName: '',
                leadSource: '',
                objection: 13,
                ineligible: 6,
                notes: '',
                zip: '',
                invalid: false,
                visible: false,
                editable: true,
                currentCarrier: "",
                insuranceScore: 0,
                currentCarrierAuto: "",
                insuranceScoreAuto: 0,
                showIneligible: false,
                selfGenerated: false,
                requote: false,
                referringAgentId: 0,
                bundle: false
            });
            this.clearProductCheckboxes();
            this.props.onVisibleChange(false);
        }
    }
    
    handleActivityLogDelete(event) {
        event.preventDefault();
        const id = this.state.id;
        const dateFrom = this.props.dateFrom;
        const dateTo = this.props.dateTo;
        const agencyId = localStorage.getItem('agencyId');

        this.props.deleteActivityLog(id, dateFrom, dateTo, agencyId, this.props.view);

        this.setState({
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            agentId: 0,
            firstName: '',
            lastName: '',
            leadSource: '',
            objection: 13,
            ineligible: 6,
            notes: '',
            zip: '',
            invalid: false,
            visible: false,
            editable: true,
            currentCarrier: "",
            insuranceScore: 0,
            currentCarrierAuto: "",
            insuranceScoreAuto: 0,
            showIneligible: false,
            selfGenerated: false,
            requote: false,
            referringAgentId: 0,
            bundle: false
        });
        this.clearProductCheckboxes();
        this.props.onVisibleChange(false);
    }
    handleOpenModal = () => {
        this.setState({ visible: true });
        console.log('ID: ' + this.state.id);
    }
    handleCloseModal = () => {
        this.setState({
            id: 0,
            date: moment().format("MM/DD/YYYY"),
            agent: '',
            agentId: 0,
            firstName: '',
            lastName: '',
            leadSource: '',
            objection: 13,
            ineligible: 6,
            notes: '',
            zip: '',
            invalid: false,
            visible: false,
            editable: true,
            currentCarrier: "",
            insuranceScore: 0,
            currentCarrierAuto: "",
            insuranceScoreAuto: 0,
            showIneligible: false,
            selfGenerated: false,
            requote: false,
            referringAgentId: 0,
            bundle: false
        });
        this.clearProductCheckboxes();
        this.props.onVisibleChange(false);
    } 
    handleBundleChange(e) {
        console.log(e.target.checked);
        this.setState({bundle: e.target.checked});
    }
    isQuoteLogValid(s){
        const id = this.state.id;
        const date = this.state.date;
        const agent = this.state.agent;
        const firstName = this.state.firstName;
        const lastName = this.state.lastName;
        const leadSource = this.state.leadSource;
        const objection = this.state.objection;
        const ineligible = this.state.ineligible;
        const notes = this.state.notes;
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.props.dateFrom;
        const dateTo = this.props.dateTo;
        const zip = this.state.zip;
        const products = this.state.carrierProductCheckboxes.filter(s => s.productCheckboxes.checked).length;
        const totalProducts = 2
        
        if(agent === undefined | firstName === undefined | lastName === undefined | leadSource === undefined | objection === undefined | ineligible === undefined | zip === undefined 
            | agent === "" | firstName === "" | lastName === "" | leadSource === ""| objection === "" | ineligible === "" | zip === "" | zip.length > 5 | zip.length < 5 | totalProducts == 0){
             return false;
        }
        else{
            return true;
        }
    }
    handleCheckZipCode(e){
        if(e.target.value.length > 5 | e.target.value.length < 5){
            this.setState({isZipValid: false})
        } else {
            this.setState({isZipValid: true});
        } 
    }
    handleInsuranceScoreChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.insuranceScore = e;
        
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleCurrentCarrierChange = (e, product) => {
        const data = this.state.carrierProductCheckboxes;
        product.typeCurrentCarrierId = e;
        
        const updatedData = data.map(group => {
            if (group.carrier.id === product.carrierId && group.productCheckboxes.productId === product.productId) {
                return {
                ...group,
                productCheckboxes: product
                };
            } else {
                return group;
            }
        });

        this.setState({ carrierProductCheckboxes: updatedData }, () => console.log(this.state.carrierProductCheckboxes));
    }
    handleInsuranceScoreAutoChange = (e) => {
        this.setState({insuranceScoreAuto: e});
    }
    handleCurrentCarrierAutoChange = (e) => {
        this.setState({currentCarrierAuto: e});
    }
    handleSelfGeneratedChange(e) {
        this.setState({selfGenerated: e.target.checked});
    }
    handleRequoteChange(e) {
        this.setState({requote: e.target.checked});
    }
    handleCarrierChange = (e) => {
        this.setState({selectedCarrier: e});
    }
    handleActiveKeyChange = (key) => {
        console.log(key);
        this.setState({
            activeAllstateKey: key,
        });
    }
    handleEMActiveKeyChange = (key) => {
        console.log(key);
        this.setState({
            activeEMKey: key,
        });
    }
    render() {
        const insCarriersList = this.props.currentCarriers; //require('../data/inscarriers.json');
        const filterLeadSource = [];

        this.props.leadSource.map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.name
            })
        );
        const userStatus = localStorage.getItem('status');
        const insuranceScores = [];
        for(let i=0; i <= 99; i++){
            insuranceScores.push(i);
        }
        var showBundle = true;
        const dwellingCnt = this.state.dwellingCheckboxes.filter(s => s.checked).length;
        const autoCnt = this.state.auto > 0 ? 1 : 0;
        const specialtyCnt = this.state.specialtyCheckboxes.filter(s => s.checked).length;
        const totalProducts = dwellingCnt + specialtyCnt + autoCnt
        if(dwellingCnt > 1 || specialtyCnt > 1 || totalProducts > 1){
            showBundle = false;
        }
        const allStateProducts = this.state.carrierProductCheckboxes.find(r => r.carrier.inactive == false && r.productCheckboxes.length > 0 && r.carrier.name == "Allstate");
        console.log(allStateProducts);
        return (
            <div>
                <Row>
                    <Col span={4} style={{fontWeight: 'bold'}}>
                        <Button shape="round" type='primary' size='large' onClick={() => this.handleOpenModal()} style={{fontWeight: 'bold', overflow: "hidden", boxShadow: '5px 5px 5px #ccc', display: this.props.hideButton ? 'none' : 'block' }}><PlusCircleOutlined /> ADD QUOTED PROSPECTS</Button>
                    </Col>
                </Row>
                {/* <Modal
                    width="75%"
                    visible={this.state.visible}
                    title="ADD QUOTED PROSPECT"
                    onOk={this.handleOk}
                    onCancel={this.handleCloseModal}
                    footer={[
                        !this.state.isSold | (userStatus == 4 && this.state.isSold) | (userStatus != 4 && this.state.isSold && this.state.originalStatus != 'SOLD') &&
                        <Row style={{paddingBottom: 10 }}>
                            <Col sm={8}>
                                <Button style={{ float: 'left', fontWeight: 'bold' }} type="primary" shape="round" onClick={this.handleSave}>
                                    <SaveOutlined /> Submit
                                </Button>
                                <p><Text style={{ float: 'left' }} type="danger" id="error">{this.props.error}</Text></p>
                                {
                                    (this.state.invalid == true) &&
                                        <p><Text style={{ float: 'left' }} type="danger" id="invalid">One or more required fields are blank.  Please try again.</Text></p>
                                }
                            </Col>
                            <Col sm={8} style={{textAlign: 'center'}}>
                            {
                                (this.state.id > 0 && this.state.isSold) &&
                                    <Button style={{ fontWeight: 'bold' }} type="primary" shape="round" onClick={() => this.setState({editable: true})}>
                                        <EditOutlined /> Edit
                                    </Button>
                            }
                            </Col>
                            <Col sm={8}>
                            {
                                (userStatus == 4) && 
                                    (this.state.id > 0) && 
                                        <Button shape="round" type="danger" onClick={this.handleDelete} style={{fontWeight: 'bold'}}>
                                            <DeleteOutlined /> Delete
                                        </Button>
                            }
                            </Col>
                        </Row>,
                    ]}> */}
                    <Drawer
                        title="ADD QUOTED PROSPECT"
                        width="80%"
                        onClose={this.handleCloseModal}
                        open={this.state.visible}
                        styles={{
                        body: {
                            paddingBottom: 80,
                        },
                        }}
                        extra={
                        <Space>
                            <Button shape="round" onClick={this.handleCloseModal}>Cancel</Button>
                            <Button shape="round" onClick={this.handleSave} type="primary">
                                Submit
                            </Button>
                            {
                            (userStatus == 4) && 
                                (this.state.id > 0) && 
                                    <Button shape="round" type="danger" onClick={this.handleActivityLogDelete} style={{fontWeight: 'bold'}}>
                                        <DeleteOutlined /> Delete
                                    </Button>
                            }
                        </Space>
                    }>
                    <Row>
                        <Col sm={8} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="agent">Agent<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Select disabled={!this.state.editable}
                                    virtual={false}
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    name="agent"
                                    value={this.state.agent}
                                    onChange={this.handleAgentChange}>
                                    {
                                        this.props.agent.filter(a => a.status != "5").map(a => 
                                            <Option key={a.agentId} value={a.firstName}>{a.firstName}</Option>
                                        )
                                    }
                                    
                                </Select>     
                            </div>
                        </Col>
                        <Col sm={8} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="state">Lead Source<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Select disabled={!this.state.editable}
                                    showSearch
                                    virtual={false}
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="leadSource"
                                    value={this.state.leadSource}
                                    filterOption={(input, option) =>
                                        (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={this.handleLeadSourceChange}>
                                    {
                                        this.props.leadSource.filter(ls => ls.inactive == false).map(ls => 
                                            <Option key={ls.name} value={ls.id}>{ls.name}</Option>
                                        )
                                    }
                                </Select>                    
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div>
                                <label htmlFor="referringAgent">Referring Agent<span style={{color: 'red', fontSize: 'large'}}></span></label><br />
                                <Select disabled={!this.state.editable}
                                    virtual={false}
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ minWidth: "20%" }}
                                    placeholder="Please select"
                                    name="referringAgent"
                                    value={this.state.referringAgentId}
                                    onChange={this.handleReferringAgentChange}>
                                        <Option key="0" value={0}> </Option>
                                    {
                                        
                                        this.props.referringAgent.filter(a => !a.inactive).map(a => 
                                            <Option key={a.id} value={a.id}>{a.name}</Option>
                                        )
                                    }
                                </Select>     
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="firstName">First Name<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Input value={this.state.firstName} name='firstName' onChange={e => this.handleChange(e)} />
                            </div>
                        </Col>
                        <Col sm={10} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="lastName">Last Name<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Input value={this.state.lastName} name='lastName' onChange={e => this.handleChange(e)} />
                            </div>
                        </Col>
                        <Col span={4} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="zip">Zip<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                <Input type="number" value={this.state.zip} name='zip' onChange={e => this.handleChange(e)} onBlur={e => this.handleCheckZipCode(e)} maxLength="5" minLength="5" />
                                {this.state.isZipValid == false ?
                                    <Alert message="Zip Code must be 5 characters" type="error" visible={!this.state.isZipValid}></Alert>
                                    :
                                    ""
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} style={{paddingRight: 5, paddingTop: 10}}>
                            <Card size="small" title={<span>Products <small>(select at least one product)</small><span style={{color: 'red', fontSize: 'large'}}>*</span></span>}>
                                <Collapse width="100%" key='Allstate' activeKey={this.state.activeAllstateKey} onChange={this.handleActiveKeyChange}>
                                {
                                    this.state.carrierProductCheckboxes.filter(r => r.carrier.inactive == false && r.productCheckboxes.length > 0 && r.carrier.name == "Allstate").map(cp =>
                                            <Panel header={<Title level={4} style={{color: "#fff"}}>{cp.carrier.name}</Title>} key="1" style={{backgroundColor: "#0000ff"}}>
                                                <div style={{backgroundColor: "#E0EDF4", padding: 5}}>  
                                                <Row style={{display: cp.productCheckboxes.filter(p => p.productType == "Dwelling" & p.inactive == false).length > 0 ? 'block' : 'none', marginTop: 10}}>
                                                    <Col span={24}>
                                                        <Title level={3}>Dwelling</Title>
                                                    </Col>
                                                </Row>
                                                {cp.productCheckboxes.filter(p => p.productType == "Dwelling" & p.inactive == false).map(product => 
                                                <Row key={product.id} style={{border: 'solid 1px #3D76DD', borderRadius: "20px", marginTop: 10, paddingLeft: 10, paddingTop: 20, paddingBottom: 20, paddingRight: 10 }}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Checkbox value={product} onChange={(e) => this.handleProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quote Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductQuoteDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(moment(product.quoteDate).format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} value={product.cnt} onChange={e => this.handleProductNumberChange(e, product)} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quoted Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductQuotePremiumChange(e, product)} value={product.quotePremium}  />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Status<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <Select
                                                                    style={{ width: '90%' }}
                                                                    placeholder="Please select"
                                                                    name="status"
                                                                    value={product.status}
                                                                    onChange={(e) => this.handleProductStatusChange(e, product)}>
                                                                    {
                                                                        this.props.status.map(a => 
                                                                            <Option key={a.name}>{a.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>     
                                                            </Col>
                                                            <Col span={5} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <div>
                                                                    <label htmlFor="objection">Objection<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        virtual={false}
                                                                        style={{ width: '90%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="objection"
                                                                        value={product.typeQuoteLogObjectionId == 0 ? "" : product.typeQuoteLogObjectionId}
                                                                        onChange={(e) => this.handleProductObjectionChange(e, product)}>
                                                                        {
                                                                            this.props.objection.map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>                    
                                                                </div>
                                                            </Col>
                                                            <Col span={3} style={{display: product.checked == false ? 'none' : 'inline', textAlign: 'center'}}>
                                                                <Checkbox value={product} checked={product.bundle} onChange={(e) => this.handleProductBundleChange(e)} style={{marginTop: 35}}>Bundle?</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {product.typeQuoteLogObjectionId == 9 | product.typeQuoteLogObjectionId == 11 | product.typeQuoteLogObjectionId == 12 ? 
                                                            <Col sm={4} style={{paddingRight: 5}}>
                                                                <div>
                                                                    <label htmlFor="ineligible">Ineligible<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        style={{ width: '90%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="ineligible"
                                                                        value={product.typeQuoteLogIneligibleId == 0 ? "" : product.typeQuoteLogIneligibleId}
                                                                        onChange={(e) => this.handleProductIneligibleChange(e, product)}>
                                                                        {
                                                                            this.props.ineligible.filter(i => i.name != "N/A").map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>      
                                                                </div>
                                                            </Col>
                                                            :
                                                            <div></div>
                                                            }
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insuranceScore">Insurance Score<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="insuranceScore"
                                                                    value={product.insuranceScore}
                                                                    onChange={(e) => this.handleInsuranceScoreChange(e, product)}>
                                                                    {
                                                                        insuranceScores.map(i => 
                                                                            <Option key={i}>{i}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insCarrier">Current Carrier<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    showSearch
                                                                    virtual={false}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="currentCarrier"
                                                                    value={product.typeCurrentCarrierId == 0 ? "" : product.typeCurrentCarrierId}
                                                                    filterOption={(input, option) =>
                                                                        (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e) => this.handleCurrentCarrierChange(e, product)}>
                                                                    {
                                                                        insCarriersList.sort().map(i => 
                                                                            <Option key={i.name} value={i.id}>{i.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        {product.status == "SOLD" &&
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductBindDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.bindDate != null ? moment(product.bindDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductEffectiveDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.effectiveDate != null ? moment(product.effectiveDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} onChange={e => this.handleProductWrittenNumberChange(e, product)} value={product.writtenCnt} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Written Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductWrittenPremiumChange(e, product)} value={product.writtenPremium} />
                                                            </Col>
                                                        </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                                )}
                                                </div>
                                                <div style={{backgroundColor: "#FBFBEF", padding: 5}}>  
                                                <Row style={{display: cp.productCheckboxes.filter(p => p.productType == "Auto" & p.inactive == false).length > 0 ? 'block' : 'none'}}>
                                                    <Col span={24}>
                                                        <Title level={3}>Auto</Title>
                                                    </Col>
                                                </Row>
                                                {cp.productCheckboxes.filter(p => p.productType == "Auto" & p.inactive == false).map(product => 
                                                <Row key={product.id} style={{border: 'solid 1px #3D76DD', borderRadius: "20px", marginTop: 10, paddingLeft: 10, paddingTop: 20, paddingBottom: 20, paddingRight: 10 }}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Checkbox value={product} onChange={(e) => this.handleProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quote Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductQuoteDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(moment(product.quoteDate).format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} value={product.cnt} onChange={e => this.handleProductNumberChange(e, product)} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quoted Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductQuotePremiumChange(e, product)} value={product.quotePremium}  />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Status<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <Select
                                                                    style={{ width: '90%' }}
                                                                    placeholder="Please select"
                                                                    name="status"
                                                                    value={product.status}
                                                                    onChange={(e) => this.handleProductStatusChange(e, product)}>
                                                                    {
                                                                        this.props.status.map(a => 
                                                                            <Option key={a.name}>{a.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>     
                                                            </Col>
                                                            <Col span={5} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <div>
                                                                    <label htmlFor="objection">Objection<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        virtual={false}
                                                                        style={{ width: '90%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="objection"
                                                                        value={product.typeQuoteLogObjectionId == 0 ? "" : product.typeQuoteLogObjectionId}
                                                                        onChange={(e) => this.handleProductObjectionChange(e, product)}>
                                                                        {
                                                                            this.props.objection.map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>                    
                                                                </div>
                                                            </Col>
                                                            <Col span={3} style={{display: product.checked == false ? 'none' : 'inline', textAlign: 'center'}}>
                                                                <Checkbox value={product} checked={product.bundle} onChange={(e) => this.handleProductBundleChange(e)} style={{marginTop: 35}}>Bundle?</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {product.typeQuoteLogObjectionId == 9 | product.typeQuoteLogObjectionId == 11 | product.typeQuoteLogObjectionId == 12 ? 
                                                            <Col sm={4} style={{paddingRight: 5}}>
                                                                <div>
                                                                    <label htmlFor="ineligible">Ineligible<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        style={{ width: '100%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="ineligible"
                                                                        value={product.typeQuoteLogIneligibleId}
                                                                        onChange={(e) => this.handleProductIneligibleChange(e, product)}>
                                                                        {
                                                                            this.props.ineligible.filter(i => i.name != "N/A").map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>      
                                                                </div>
                                                            </Col>
                                                            :
                                                            <div></div>
                                                            }
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insuranceScore">Insurance Score<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="insuranceScore"
                                                                    value={product.insuranceScore}
                                                                    onChange={(e) => this.handleInsuranceScoreChange(e, product)}>
                                                                    {
                                                                        insuranceScores.map(i => 
                                                                            <Option key={i}>{i}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insCarrier">Current Carrier<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    showSearch
                                                                    virtual={false}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="currentCarrier"
                                                                    value={product.typeCurrentCarrierId == 0 ? "" : product.typeCurrentCarrierId}
                                                                    filterOption={(input, option) =>
                                                                        (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e) => this.handleCurrentCarrierChange(e, product)}>
                                                                    {
                                                                        insCarriersList.sort().map(i => 
                                                                            <Option key={i.name} value={i.id}>{i.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        {product.status == "SOLD" &&
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductBindDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.bindDate != null ? moment(product.bindDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductEffectiveDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.effectiveDate != null ? moment(product.effectiveDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} onChange={e => this.handleProductWrittenNumberChange(e, product)} value={product.writtenCnt} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Written Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductWrittenPremiumChange(e, product)} value={product.writtenPremium} />
                                                            </Col>
                                                        </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                                )}
                                                </div>
                                                <div style={{backgroundColor: "#F3F3F3", padding: 5}}>  
                                                <Row style={{display: cp.productCheckboxes.filter(p => p.productType == "Specialty" & p.inactive == false).length > 0 ? 'block' : 'none'}}>
                                                    <Col span={24}> 
                                                        <Title level={3}>Specialty</Title>
                                                    </Col>
                                                </Row>
                                                {cp.productCheckboxes.filter(p => p.productType == "Specialty" & p.inactive == false).map(product => 
                                                <Row key={product.id} style={{border: 'solid 1px #3D76DD', borderRadius: "20px", marginTop: 10, paddingLeft: 10, paddingTop: 20, paddingBottom: 20, paddingRight: 10 }}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Checkbox value={product} onChange={(e) => this.handleProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quote Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductQuoteDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(moment(product.quoteDate).format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} value={product.cnt} onChange={e => this.handleProductNumberChange(e, product)} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quoted Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductQuotePremiumChange(e, product)} value={product.quotePremium}  />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Status<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <Select
                                                                    style={{ width: '90%' }}
                                                                    placeholder="Please select"
                                                                    name="status"
                                                                    value={product.status}
                                                                    onChange={(e) => this.handleProductStatusChange(e, product)}>
                                                                    {
                                                                        this.props.status.map(a => 
                                                                            <Option key={a.name}>{a.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>     
                                                            </Col>
                                                            <Col span={5} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <div>
                                                                    <label htmlFor="objection">Objection<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        virtual={false}
                                                                        style={{ width: '90%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="objection"
                                                                        value={product.typeQuoteLogObjectionId == 0 ? "" : product.typeQuoteLogObjectionId}
                                                                        onChange={(e) => this.handleProductObjectionChange(e, product)}>
                                                                        {
                                                                            this.props.objection.map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>                    
                                                                </div>
                                                            </Col>
                                                            <Col span={3} style={{display: product.checked == false ? 'none' : 'inline', textAlign: 'center'}}>
                                                                <Checkbox value={product} checked={product.bundle} onChange={(e) => this.handleProductBundleChange(e)} style={{marginTop: 35}}>Bundle?</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {product.typeQuoteLogObjectionId == 9 | product.typeQuoteLogObjectionId == 11 | product.typeQuoteLogObjectionId == 12 ? 
                                                            <Col sm={4} style={{paddingRight: 5}}>
                                                                <div>
                                                                    <label htmlFor="ineligible">Ineligible<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        style={{ width: '100%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="ineligible"
                                                                        value={product.typeQuoteLogIneligibleId}
                                                                        onChange={(e) => this.handleProductIneligibleChange(e, product)}>
                                                                        {
                                                                            this.props.ineligible.filter(i => i.name != "N/A").map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>      
                                                                </div>
                                                            </Col>
                                                            :
                                                            <div></div>
                                                            }
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insuranceScore">Insurance Score<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="insuranceScore"
                                                                    value={product.insuranceScore}
                                                                    onChange={(e) => this.handleInsuranceScoreChange(e, product)}>
                                                                    {
                                                                        insuranceScores.map(i => 
                                                                            <Option key={i}>{i}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insCarrier">Current Carrier<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    showSearch
                                                                    virtual={false}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="currentCarrier"
                                                                    value={product.typeCurrentCarrierId == 0 ? "" : product.typeCurrentCarrierId}
                                                                    filterOption={(input, option) =>
                                                                        (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e) => this.handleCurrentCarrierChange(e, product)}>
                                                                    {
                                                                        insCarriersList.sort().map(i => 
                                                                            <Option key={i.name} value={i.id}>{i.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        {product.status == "SOLD" &&
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductBindDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.bindDate != null ? moment(product.bindDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductEffectiveDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.effectiveDate != null ? moment(product.effectiveDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} onChange={e => this.handleProductWrittenNumberChange(e, product)} value={product.writtenCnt} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Written Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductWrittenPremiumChange(e, product)} value={product.writtenPremium} />
                                                            </Col>
                                                        </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                                )}
                                                </div>
                                            </Panel>
                                        
                                    )
                                }
                                </Collapse>
                                <Collapse width="100%" activeKey={this.state.activeMainEMKey} onChange={(e) => this.setState({activeMainEMKey: e})}>
                                    <Panel key="1" header={<Title level={4} style={{color: "#000"}}>Expanded Marketplace</Title>} style={{backgroundColor: "#B2B2FF"}}>
                                        <Collapse width="100%" onChange={this.handleEMActiveKeyChange} activeKey={this.state.activeEMKey}>
                                            {
                                            this.state.carrierProductCheckboxes.filter(r => r.carrier.inactive == false && r.productCheckboxes.length > 0 && r.carrier.name != "Allstate").map(cp =>
                                            <Panel header={<Title level={4} style={{color: "#000"}}>{cp.carrier.name}</Title>} key={cp.carrier.id} style={{backgroundColor: "#ccc"}}>
                                                <Row style={{display: cp.productCheckboxes.filter(p => p.productType == "Dwelling" & p.inactive == false).length > 0 ? 'block' : 'none', marginTop: 10}}>
                                                    <Col span={24}>
                                                        <Title level={3}>Dwelling</Title>
                                                    </Col>
                                                </Row>
                                                {cp.productCheckboxes.filter(p => p.productType == "Dwelling" & p.inactive == false).map(product => 
                                                <Row key={product.id} style={{border: 'solid 1px #3D76DD', borderRadius: "20px", marginTop: 10, paddingLeft: 10, paddingTop: 20, paddingBottom: 20, paddingRight: 10 }}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Checkbox value={product} onChange={(e) => this.handleProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quote Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductQuoteDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(moment(product.quoteDate).format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} value={product.cnt} onChange={e => this.handleProductNumberChange(e, product)} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quoted Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductQuotePremiumChange(e, product)} value={product.quotePremium}  />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Status<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <Select
                                                                    style={{ width: '90%' }}
                                                                    placeholder="Please select"
                                                                    name="status"
                                                                    value={product.status}
                                                                    onChange={(e) => this.handleProductStatusChange(e, product)}>
                                                                    {
                                                                        this.props.status.map(a => 
                                                                            <Option key={a.name}>{a.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>     
                                                            </Col>
                                                            <Col span={5} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <div>
                                                                    <label htmlFor="objection">Objection<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        virtual={false}
                                                                        style={{ width: '90%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="objection"
                                                                        value={product.typeQuoteLogObjectionId == 0 ? "" : product.typeQuoteLogObjectionId}
                                                                        onChange={(e) => this.handleProductObjectionChange(e, product)}>
                                                                        {
                                                                            this.props.objection.map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>                    
                                                                </div>
                                                            </Col>
                                                            <Col span={3} style={{display: product.checked == false ? 'none' : 'inline', textAlign: 'center'}}>
                                                                <Checkbox value={product} checked={product.bundle} onChange={(e) => this.handleProductBundleChange(e)} style={{marginTop: 35}}>Bundle?</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {product.typeQuoteLogObjectionId == 9 | product.typeQuoteLogObjectionId == 11 | product.typeQuoteLogObjectionId == 12 ? 
                                                            <Col sm={4} style={{paddingRight: 5}}>
                                                                <div>
                                                                    <label htmlFor="ineligible">Ineligible<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        style={{ width: '100%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="ineligible"
                                                                        value={product.typeQuoteLogIneligibleId}
                                                                        onChange={(e) => this.handleProductIneligibleChange(e, product)}>
                                                                        {
                                                                            this.props.ineligible.filter(i => i.name != "N/A").map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>      
                                                                </div>
                                                            </Col>
                                                            :
                                                            <div></div>
                                                            }
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insuranceScore">Insurance Score<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="insuranceScore"
                                                                    value={product.insuranceScore}
                                                                    onChange={(e) => this.handleInsuranceScoreChange(e, product)}>
                                                                    {
                                                                        insuranceScores.map(i => 
                                                                            <Option key={i}>{i}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insCarrier">Current Carrier<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    showSearch
                                                                    virtual={false}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="currentCarrier"
                                                                    value={product.typeCurrentCarrierId == 0 ? "" : product.typeCurrentCarrierId}
                                                                    filterOption={(input, option) =>
                                                                        (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e) => this.handleCurrentCarrierChange(e, product)}>
                                                                    {
                                                                        insCarriersList.sort().map(i => 
                                                                            <Option key={i.name} value={i.id}>{i.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        {product.status == "SOLD" &&
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductBindDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.bindDate != null ? moment(product.bindDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductEffectiveDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.effectiveDate != null ? moment(product.effectiveDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} onChange={e => this.handleProductWrittenNumberChange(e, product)} value={product.writtenCnt} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Written Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductWrittenPremiumChange(e, product)} value={product.writtenPremium} />
                                                            </Col>
                                                        </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                                )}
                                                <Row style={{display: cp.productCheckboxes.filter(p => p.productType == "Auto" & p.inactive == false).length > 0 ? 'block' : 'none'}}>
                                                    <Col span={24}>
                                                        <Title level={3}>Auto</Title>
                                                    </Col>
                                                </Row>
                                                {cp.productCheckboxes.filter(p => p.productType == "Auto" & p.inactive == false).map(product => 
                                                <Row key={product.id} style={{border: 'solid 1px #3D76DD', borderRadius: "20px", marginTop: 10, paddingLeft: 10, paddingTop: 20, paddingBottom: 20, paddingRight: 10 }}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Checkbox value={product} onChange={(e) => this.handleProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quote Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductQuoteDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(moment(product.quoteDate).format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} value={product.cnt} onChange={e => this.handleProductNumberChange(e, product)} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quoted Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductQuotePremiumChange(e, product)} value={product.quotePremium}  />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Status<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <Select
                                                                    style={{ width: '90%' }}
                                                                    placeholder="Please select"
                                                                    name="status"
                                                                    value={product.status}
                                                                    onChange={(e) => this.handleProductStatusChange(e, product)}>
                                                                    {
                                                                        this.props.status.map(a => 
                                                                            <Option key={a.name}>{a.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>     
                                                            </Col>
                                                            <Col span={5} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <div>
                                                                    <label htmlFor="objection">Objection<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        virtual={false}
                                                                        style={{ width: '90%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="objection"
                                                                        value={product.typeQuoteLogObjectionId == 0 ? "" : product.typeQuoteLogObjectionId}
                                                                        onChange={(e) => this.handleProductObjectionChange(e, product)}>
                                                                        {
                                                                            this.props.objection.map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>                    
                                                                </div>
                                                            </Col>
                                                            <Col span={3} style={{display: product.checked == false ? 'none' : 'inline', textAlign: 'center'}}>
                                                                <Checkbox value={product} checked={product.bundle} onChange={(e) => this.handleProductBundleChange(e)} style={{marginTop: 35}}>Bundle?</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {product.typeQuoteLogObjectionId == 9 | product.typeQuoteLogObjectionId == 11 | product.typeQuoteLogObjectionId == 12 ?
                                                            <Col sm={4} style={{paddingRight: 5}}>
                                                                <div>
                                                                    <label htmlFor="ineligible">Ineligible<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        style={{ width: '100%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="ineligible"
                                                                        value={product.typeQuoteLogIneligibleId}
                                                                        onChange={(e) => this.handleProductIneligibleChange(e, product)}>
                                                                        {
                                                                            this.props.ineligible.filter(i => i.name != "N/A").map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>      
                                                                </div>
                                                            </Col>
                                                            :
                                                            <div></div>
                                                            }
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insuranceScore">Insurance Score<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="insuranceScore"
                                                                    value={product.insuranceScore}
                                                                    onChange={(e) => this.handleInsuranceScoreChange(e, product)}>
                                                                    {
                                                                        insuranceScores.map(i => 
                                                                            <Option key={i}>{i}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insCarrier">Current Carrier<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    showSearch
                                                                    virtual={false}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="currentCarrier"
                                                                    value={product.typeCurrentCarrierId == 0 ? "" : product.typeCurrentCarrierId}
                                                                    filterOption={(input, option) =>
                                                                        (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e) => this.handleCurrentCarrierChange(e, product)}>
                                                                    {
                                                                        insCarriersList.sort().map(i => 
                                                                            <Option key={i.name} value={i.id}>{i.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        {product.status == "SOLD" &&
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductBindDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.bindDate != null ? moment(product.bindDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductEffectiveDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.effectiveDate != null ? moment(product.effectiveDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} onChange={e => this.handleProductWrittenNumberChange(e, product)} value={product.writtenCnt} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Written Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductWrittenPremiumChange(e, product)} value={product.writtenPremium} />
                                                            </Col>
                                                        </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                                )}
                                                <Row style={{display: cp.productCheckboxes.filter(p => p.productType == "Specialty" & p.inactive == false).length > 0 ? 'block' : 'none'}}>
                                                    <Col span={24}> 
                                                        <Title level={3}>Specialty</Title>
                                                    </Col>
                                                </Row>
                                                {cp.productCheckboxes.filter(p => p.productType == "Specialty" & p.inactive == false).map(product => 
                                                <Row key={product.id} style={{border: 'solid 1px #3D76DD', borderRadius: "20px", marginTop: 10, paddingLeft: 10, paddingTop: 20, paddingBottom: 20, paddingRight: 10 }}>
                                                    <Col span={24}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Checkbox value={product} onChange={(e) => this.handleProductChange(e)} checked={product.checked}>{product.name}</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quote Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductQuoteDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(moment(product.quoteDate).format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} value={product.cnt} onChange={e => this.handleProductNumberChange(e, product)} />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Quoted Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductQuotePremiumChange(e, product)} value={product.quotePremium}  />
                                                            </Col>
                                                            <Col span={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label>Status<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <Select
                                                                    style={{ width: '90%' }}
                                                                    placeholder="Please select"
                                                                    name="status"
                                                                    value={product.status}
                                                                    onChange={(e) => this.handleProductStatusChange(e, product)}>
                                                                    {
                                                                        this.props.status.map(a => 
                                                                            <Option key={a.name}>{a.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>     
                                                            </Col>
                                                            <Col span={5} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <div>
                                                                    <label htmlFor="objection">Objection<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        virtual={false}
                                                                        style={{ width: '90%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="objection"
                                                                        value={product.typeQuoteLogObjectionId == 0 ? "" : product.typeQuoteLogObjectionId}
                                                                        onChange={(e) => this.handleProductObjectionChange(e, product)}>
                                                                        {
                                                                            this.props.objection.map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>                    
                                                                </div>
                                                            </Col>
                                                            <Col span={3} style={{display: product.checked == false ? 'none' : 'inline', textAlign: 'center'}}>
                                                                <Checkbox value={product} checked={product.bundle} onChange={(e) => this.handleProductBundleChange(e)} style={{marginTop: 35}}>Bundle?</Checkbox>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {product.typeQuoteLogObjectionId == 9 | product.typeQuoteLogObjectionId == 11 | product.typeQuoteLogObjectionId == 12 ? 
                                                            <Col sm={4} style={{paddingRight: 5}}>
                                                                <div>
                                                                    <label htmlFor="ineligible">Ineligible<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                    <Select disabled={!this.state.editable}
                                                                        style={{ width: '100%' }}
                                                                        dropdownStyle={{ minWidth: "20%" }}
                                                                        placeholder="Please select"
                                                                        name="ineligible"
                                                                        value={product.typeQuoteLogIneligibleId}
                                                                        onChange={(e) => this.handleProductIneligibleChange(e, product)}>
                                                                        {
                                                                            this.props.ineligible.filter(i => i.name != "N/A").map(d => 
                                                                                <Option key={d.name} value={d.id}>{d.name}</Option>
                                                                            )
                                                                        }
                                                                    </Select>      
                                                                </div>
                                                            </Col>
                                                            :
                                                            <div></div>
                                                            }
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insuranceScore">Insurance Score<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="insuranceScore"
                                                                    value={product.insuranceScore}
                                                                    onChange={(e) => this.handleInsuranceScoreChange(e, product)}>
                                                                    {
                                                                        insuranceScores.map(i => 
                                                                            <Option key={i}>{i}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                            <Col sm={4} style={{display: product.checked == false ? 'none' : 'inline'}}>
                                                                <label htmlFor="insCarrier">Current Carrier<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Select disabled={!this.state.editable}
                                                                    showSearch
                                                                    virtual={false}
                                                                    style={{ width: '90%' }}
                                                                    dropdownStyle={{ minWidth: "20%" }}
                                                                    placeholder="Please select"
                                                                    name="currentCarrier"
                                                                    value={product.typeCurrentCarrierId == 0 ? "" : product.typeCurrentCarrierId}
                                                                    filterOption={(input, option) =>
                                                                        (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e) => this.handleCurrentCarrierChange(e, product)}>
                                                                    {
                                                                        insCarriersList.sort().map(i => 
                                                                            <Option key={i.name} value={i.id}>{i.name}</Option>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </Col>
                                                        </Row>
                                                        {product.status == "SOLD" &&
                                                        <Row>
                                                            <Col span={4}>
                                                                <label>Bind Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductBindDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.bindDate != null ? moment(product.bindDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Effective Date<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <DatePicker onChange={(e) => this.handleProductEffectiveDateChange(product, e)} format={'MM/DD/YYYY'} style={{width: '90%'}} value={moment(product.effectiveDate != null ? moment(product.effectiveDate).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY"), 'MM/DD/YYYY')} disabled={!this.state.editable} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Item Count<span style={{color: 'red', fontSize: 'large'}}>*</span></label><br />
                                                                <InputNumber type="number" min={0} max={15} step={1} style={{width: '90%'}} onChange={e => this.handleProductWrittenNumberChange(e, product)} value={product.writtenCnt} />
                                                            </Col>
                                                            <Col span={4}>
                                                                <label>Written Premium<span style={{fontSize: 'large'}}></span></label><br />
                                                                <Input type="number" formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')} name='premium' placeholder="Premium" style={{width: '90%'}} onChange={e => this.handleProductWrittenPremiumChange(e, product)} value={product.writtenPremium} />
                                                            </Col>
                                                        </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                                )}
                                            </Panel>
                                            )
                                        }
                                        </Collapse>
                                    </Panel>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row style={{paddingTop: 10}}>
                        <Col sm={4} style={{paddingRight: 5}}>
                            <Checkbox checked={this.state.selfGenerated} name="selfGenerated" onChange={(e) => this.handleSelfGeneratedChange(e)}>Self Generated?</Checkbox>
                        </Col>
                        <Col sm={4} style={{paddingRight: 5}}>
                            <Checkbox checked={this.state.requote} name="requote" onChange={(e) => this.handleRequoteChange(e)}>Requote / Aged Lead</Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={24} style={{paddingRight: 5}}>
                            <div>
                                <label htmlFor="spend">Notes</label><br />
                                <TextArea rows={2} name='notes' value={this.state.notes} onChange={e => this.handleChange(e)} />
                            </div>  
                        </Col>
                    </Row>
                </Drawer>
                <Modal 
                    width="25%"
                    open={this.state.invalidModalVisible}
                    title="MISSING FIELD"
                    onOk={() => this.setState({invalidModalVisible: false})}
                    onCancel={() => this.setState({invalidModalVisible: false})}
                    footer={[
                        <Row style={{paddingBottom: 10}} >
                            <Col sm={12}>
                                <Button style={{ float: 'left' }} type="primary" shape="round" onClick={() => this.setState({invalidModalVisible: false})}>
                                    OK
                                </Button>
                            </Col>
                        </Row>,
                    ]}>
                        <Text style={{ float: 'left', fontSize: 16 }} type="danger" id="invalid">One or more required fields are blank.  Please try again.</Text>
                </Modal>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ActivityLogEntry);