import React, { Component  } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, InputNumber, Row, Col, Typography, Select, Button, DatePicker, Table, Collapse, Radio, Space, Modal, Checkbox, Spin, Tooltip } from 'antd';
import { SearchOutlined, DownloadOutlined, CheckOutlined, FunnelPlotOutlined, ArrowLeftOutlined, ArrowRightOutlined, TableOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import ActivityLogEntry from './ActivityLogEntry';
import ActivityLogAgentSummary from './ActivityLogAgentSummary';
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


class ActivityLogReportDetailed extends Component {
    constructor(props) {
      super(props);
        this.state = {
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            searchFilter: 'date',
            openSearch: true,
            selectedQuoteLog: [],
            reportFilter: 'All',
            selectedColumns: [],
            modalVisible: false,
            columns: [],
            pageNumber: 1,
            rows: 50,
            activitySummaryRefresh: false,
            loggedInAgentName: '',
            tableSearch: [],
            filteredInfo: [],
            showConfetti: false,
            recycleConfetti: true,
            showFullNote: false,
            productFilter: 'All'
        }
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleTableClick = this.handleTableClick.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleOpenSearch = this.handleOpenSearch.bind(this);
        this.handleDateRadioChange = this.handleDateRadioChange.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.exportToCSV = this.exportToCSV.bind(this);
        this.handlePanelChange = this.handlePanelChange.bind(this);
        this.handleReportRadioChange = this.handleReportRadioChange.bind(this);
        this.handleColumnChange = this.handleColumnChange.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.handleProductRadioChange = this.handleProductRadioChange.bind(this);
        this.handleEntryVisibleChange = this.handleEntryVisibleChange.bind(this);
        this.handleViewChange = this.handleViewChange.bind(this);
    }
    
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        if(agencyId == null){
            this.props.history.push('/');
        }
        this.props.getActivityLogDetailed(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), this.state.reportFilter);
        const selectedColumns = localStorage.getItem('selectedColumnsDetail');
        const selectedColumnsArray = selectedColumns != null ? selectedColumns.split(',') : [];
        this.setState({selectedColumns: selectedColumns == null || selectedColumns == "" ? [] : selectedColumnsArray});
        console.log(selectedColumnsArray);
    }
    componentDidUpdate(prevProps){
        
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleDateRangeChange(dates, dateStrings, info) {
        console.log(info);
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        if(dateFrom != ''){
            this.props.getActivityLogDetailed(agencyId, dateFrom, dateTo, this.state.reportFilter);
            this.setState({
                dateFrom: dateFrom,
                dateTo: dateTo 
            });
        }
        console.log(dateStrings);
    }
    handleTableClick = (activityLog) => {
        const agencyId = localStorage.getItem('agencyId');
        this.setState({
            selectedActivityLog: activityLog,
            visible: true
        });
    }
    
    handleSearch = (searchText) => {
        const agencyId = localStorage.getItem('agencyId');
        const searchFilter = this.state.searchFilter;
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        this.props.getActivityLogSearchDetailed(agencyId, searchText);
    };
    handleTableSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        if(selectedKeys[0] == undefined) {
            selectedKeys[0] = '';
        }
        console.log("Search: " + selectedKeys[0]);
        const tableSearch = this.state.tableSearch;
        let searchData = { searchIndex: dataIndex, searchText: selectedKeys[0] };
        const i = tableSearch.findIndex(e => e.searchIndex === dataIndex);
        if (i > -1) {
            tableSearch[i] = searchData;
        } else {
            tableSearch.push(searchData);
        }
        console.log(tableSearch);
        this.setState({
            tableSearch: tableSearch,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
      };
    handleReset = clearFilters => {
        clearFilters();
        //this.setState({ searchText: '' });
    };
    handleCloseModal = () => {
        this.setState({
            modalVisible: false,
        });
        localStorage.setItem('selectedColumnsDetail', this.state.selectedColumns);
        console.log(this.state.selectedColumns);
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#97E45D' : undefined }} />,
        onFilter: (value, record) =>
        record != null &&
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
    });
    handleProductRadioChange(e) {
        this.setState({
            productFilter: e.target.value
        });
    }
    handleRadioChange(e) {
        this.setState({
            searchFilter: e.target.value
        });
        if(e.target.value == 'all'){
            const dateFrom = '';
            const dateTo = '';
            const agencyId = localStorage.getItem('agencyId');
            
            this.props.getActivityLogDetailed(agencyId, dateFrom, dateTo, this.state.reportFilter);
            this.setState({
                dateFrom: dateFrom,
                dateTo: dateTo 
            });
        }
    }
    handleDateRadioChange(e) {
        this.setState({
            dateFilter: e.target.value
        });
        var dateFrom = '';
        var dateTo = ''; 
        const agencyId = localStorage.getItem('agencyId');
        if(e.target.value == 'today'){
            dateFrom = moment().format('MM/DD/YYYY');
            dateTo = moment().format('MM/DD/YYYY'); 
        }
        if(e.target.value == 'thisWeek'){
            dateFrom = moment().startOf('week').format('MM/DD/YYYY');
            dateTo = moment().endOf('week').format('MM/DD/YYYY'); 
        }
        else if(e.target.value == 'lastWeek'){
            dateFrom = moment().subtract(1, 'week').startOf('week').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'week').endOf('week').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'thisMonth'){
            dateFrom = moment().startOf('month').format('MM/DD/YYYY');
            dateTo = moment().endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'lastMonth'){
            dateFrom = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
            dateTo = moment().subtract(1, 'month').endOf('month').format('MM/DD/YYYY');
        }
        else if(e.target.value == 'all'){
            dateFrom = '';
            dateTo = '';
        }
        this.props.getActivityLogDetailed(agencyId, dateFrom, dateTo, this.state.reportFilter);
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo 
        });
    }
    handleOpenSearch() {
        const openSearch = this.state.openSearch;
        this.setState({
            openSearch: !openSearch
        });
    }
    exportToCSV = (columns) => {
        //e.preventDefault();
        console.log(columns);
        var data, filename, link;
        var csv = this.convertArrayOfObjectsToCSV(this.props.activityLogDetailed, columns);
        if (csv == null)
          return;
    
        filename = 'ActivityLog' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    
        //console.log(arrOrders);
      }
    
      convertArrayOfObjectsToCSV(args, columns) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        //result += keys.join(columnDelimiter);
        keys.forEach(function(key) {
            var column = columns.find(c => c.key == key);
            console.log(column);
            if(column != undefined){
                result += column.title;
                result += columnDelimiter;
            }
            else{
                result += key;
                result += columnDelimiter;
            }
        })
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                //if(key != 'id' && key != 'agencyid' && key != 'createdDts' && key != 'typeQuoteLogLeadSourceId'){
                    if (ctr > 0) result += columnDelimiter;
                    if(key == 'notes' || key == 'objection'){
                        if(item[key] != null){
                            var val = item[key].toString();
                            val = val.replace(/[\n\r]+/g, ' ');
                            val = val.replaceAll(',', ' ');
                            result += val;
                        } else {
                            result += "";
                        }
                        
                    }
                    else{
                        result += item[key]
                    }
                    ctr++;
                //}
            });
            result += lineDelimiter;
        });
    
        return result;
    };
    handlePanelChange = (open) => {
        if(open){
            this.setState({dateFrom: null, dateTo: null, dateFilter: ''})
        }
    }
    handleReportRadioChange(e) {
        this.setState({reportFilter: e.target.value})
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo; 
        if(this.state.productFilter == "Allstate" || this.state.productFilter == "All"){
            this.props.getActivityLogDetailed(agencyId, dateFrom, dateTo, e.target.value);
        } 
    }
    handleColumnChange(value){
        console.log(value);
        this.setState({selectedColumns: value});
    }
    handleViewChange(e) {
        this.props.onChangeView(e.target.value);
    }
    copyToClipboard (text) {
        navigator.clipboard.writeText(text);
    
        console.log("Copied: " + text);
    }
    handleEntryVisibleChange = (status) => {
        // if(status == "SOLD"){
        //     this.setState({showConfetti: true});
        //     setTimeout(() => {
        //         this.setState({ text: "show confetti", recycleConfetti: false });
        //     }, 5000);
        // }
        const visible = this.state.visible;
        this.setState({ visible: status });
        const seed = Math.random();
        console.log(status);
        if(!visible == false){
            this.setState({selectedActivityLog: [], seed: seed, showConfetti: false, recycleConfetti: true});
        }
    }
    render() {
        
        const filterLeadSource = [];
        this.props.leadSource.map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.name
            })
        );
        const filterAgent = [];
        this.props.agent.filter(a => a.status != "5").map (ls =>
            filterAgent.push({
                text: ls.firstName,
                value: ls.firstName
            })
        );
        const filterReferringAgent = [];
        filterReferringAgent.push({
            text: "",
            value: ""
        })
        this.props.referringAgent.map (ra =>
            filterReferringAgent.push({
                text: ra.name,
                value: ra.name
            })
        );
        const filterObjection = [];
        this.props.objection.map (o =>
            filterObjection.push({
                text: o.name,
                value: o.name
            })
        );
        const filterIneligible = [];
        this.props.ineligible.map (o =>
            filterIneligible.push({
                text: o.name,
                value: o.name
            })
        );
        filterReferringAgent.sort();
        const dateFormat = 'MM/DD/YYYY';
        var showFullNote = this.state.showFullNote;
        console.log(showFullNote);
        const columns = [
            {
                title: 'Quote Date',
                dataIndex: 'quoteDate',
                key: 'quoteDate',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.quoteDate : [],
                render: date => moment(date).format(dateFormat),
            },
            {
                title: 'Bind Date',
                dataIndex: 'bindDate',
                key: 'bindDate',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.binddate : [],
                render: date => moment(date).format(dateFormat) != '01/01/1900' && date != "" ? moment(date).format(dateFormat) : '',
            },
            {
                title: 'Agent',
                dataIndex: 'agent',
                key: 'agent',
                filters: filterAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.agent : [],
                onFilter: (value, record) => record.agent.indexOf(value) === 0,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                filters: [
                    {
                        text: 'HOT',
                        value: 'HOT',
                    },
                    {
                        text: 'WARM',
                        value: 'WARM',
                    },
                    {
                        text: 'COLD',
                        value: 'COLD',
                    },
                    {
                        text: 'TERMINATED',
                        value: 'TERMINATED',
                    },
                  ],
                  filterMultiple: true,
                  filteredValue: this.state.filteredInfo ? this.state.filteredInfo.status : [],
                  //defaultFilteredValue: this.state.tableSearch.filter(t => t.searchIndex == 'status').length > 0 ? [this.state.tableSearch.filter(t => t.searchIndex == 'status')[0].searchText] : [],
                  onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
            
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.firstName : [],
                ...this.getColumnSearchProps('firstName'),
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.lastName : [],
                ...this.getColumnSearchProps('lastName'),
            },
            {
                title: 'Lead Source',
                dataIndex: 'leadSource',
                key: 'leadSource',
                render: (text, record) => this.props.leadSource.filter(l => l.id == text).length == 0 ? text : this.props.leadSource.find(l => l.id == text).name,
                filters: filterLeadSource,
                filterMultiple: true,
                filterSearch: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.leadSource : [],
                onFilter: (value, record) => record.leadSource.indexOf(value) === 0,
            },
            {
                title: 'Carrier',
                dataIndex: 'carrierName',
                key: 'carrierName',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.carrierName : [],
                ...this.getColumnSearchProps('carrierName'),
            },
            {
                title: 'Product',
                dataIndex: 'product',
                key: 'product',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.product : [],
                ...this.getColumnSearchProps('product'),
            },
            
            {
                title: 'Item Count',
                dataIndex: 'cnt',
                key: 'cnt',
                align: 'center',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.cnt : [],
                ...this.getColumnSearchProps('cnt'),
            },
            
            {
                title: 'Written Item Count',
                dataIndex: 'writtenCnt',
                key: 'writtenCnt',
                align: 'center',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.writtenCnt : [],
                ...this.getColumnSearchProps('writtenCnt'),
            },
            {
                title: 'Written Premium',
                dataIndex: 'writtenPremium',
                key: 'writtenCnt',
                align: 'center',
                render: (text, record) => new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                }).format(text),
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.writtenPremium : [],
                ...this.getColumnSearchProps('writtenPremium'),
            },
            {
                title: 'Objection',
                dataIndex: 'objection',
                key: 'objection',
                filters: filterObjection,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.objection : [],
                onFilter: (value, record) => record.objection.indexOf(value) === 0,
            },
            {
                title: 'Ineligible',
                dataIndex: 'ineligible',
                key: 'ineligible',
                filters: filterIneligible,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.ineligible : [],
                onFilter: (value, record) => record.ineligible.indexOf(value) === 0,
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.zip : [],
                ...this.getColumnSearchProps('zip'),
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes',
                width: 500,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.notes : [],
                render: (text, record) => {
                return(
                    text.length > 80 && showFullNote != record.id ?
                    <div>
                        <Tooltip title="copy to clipboard" >
                            <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} style={{display: text.length == 0 ? 'none' : 'block'}} onClick={() => this.copyToClipboard(text)} />
                        </Tooltip>
                        <Text>{text.substring(0, 80) + "..."}</Text>
                        <Button type="link" onClick={() => this.setState({showFullNote: record.id})}>read more</Button>
                    </div>
                    :
                    <div>
                        <Tooltip title="copy to clipboard" >
                            <Button type="primary" size="small" shape="circle" icon={<CopyOutlined />} style={{display: text.length == 0 ? 'none' : 'block'}} onClick={() => this.copyToClipboard(text)} />
                        </Tooltip>
                        <Text>{text}</Text>
                        {text.length > 80 &&
                        <Button type="link" onClick={() => this.setState({showFullNote: ''})}>read less</Button>
                        }
                    </div>
                )
                // <div>{text.length > 80 && showFullNote == false && text.substring(0, 80) + "..."} {text.length > 80 && <Button type="link" onClick={() => this.setState({showFullNote: true})}>read more</Button>}  {text.length <= 80 && text}</div>
                },
                ...this.getColumnSearchProps('notes'),
            },
            {
                title: 'Bundle',
                dataIndex: 'bundle',
                key: 'bundle',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.bundle : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.bundle === value
            },
            {
                title: 'Self Gen Quotes',
                dataIndex: 'selfGenerated',
                key: 'selfGenerated',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.selfGenerated : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.selfGenerated === value
            },
            {
                title: 'Requote / Aged Lead',
                dataIndex: 'requote',
                key: 'requote',
                filters: [
                    {
                        text: 'Yes',
                        value: true,
                    },
                    {
                        text: 'No',
                        value: false,
                    },
                ],
                filterMultiple: false,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.requote : [],
                align: 'center',
                render: (text, record) => text == true ? <CheckOutlined style={{fontSize: '20px'}} /> : "",
                onFilter: (value, record) => record.requote === value
            },
            {
                title: 'Ins Score',
                dataIndex: 'insuranceScore',
                key: 'insuranceScore',
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.insuranceScore : [],
                ...this.getColumnSearchProps('insuranceScore'),
            },
            
            {
                title: 'Referring Agent',
                dataIndex: 'referringAgentName',
                key: 'referringAgentName',
                filters: filterReferringAgent,
                filterMultiple: true,
                filteredValue: this.state.filteredInfo ? this.state.filteredInfo.referringAgentName : [],
                onFilter: (value, record) => record.referringAgentName == value,
                
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => <a onClick={() => this.handleTableClick(record)}>Edit</a>,
            },
        ];
        const filteredColumns = this.state.selectedColumns.length == 0 ? columns : columns.filter(c => this.state.selectedColumns.includes(c.title));
        const userId = localStorage.getItem('userId');
        const loggedInAgent = this.props.agent.filter(a => a.userId == userId);
        const loggedInAgentName = loggedInAgent.length == 0 ? '' : loggedInAgent[0].firstName;
        const loggedInAgentId = loggedInAgent.length == 0 ? '' : loggedInAgent[0].agentId;
        const onlySelf = loggedInAgent.length == 0 ? false : loggedInAgent[0].onlySelf
        console.log(loggedInAgentName + " - " + onlySelf);
        const handleChange = (pagination, filters, sorter) => {
            console.log('Various parameters', pagination, filters, sorter);
            this.setState({filteredInfo: filters});
        };
        const clearFilters = () => {
            console.log('clear filters');
            this.setState({filteredInfo: [], dateFilter: '', reportFilter: 'All', dateFrom: moment().startOf('month').format("MM/DD/YYYY"), dateTo: moment().endOf('month').format("MM/DD/YYYY")});
            const agencyId = localStorage.getItem('agencyId');
            this.props.getActivityLog(agencyId, moment().startOf('month').format("MM/DD/YYYY"), moment().endOf('month').format("MM/DD/YYYY"), 'All');
        };
        var filterActivityLog = this.state.productFilter == 'Allstate' ? this.props.activityLogDetailed.filter(a => a.carrierName == "Allstate") : this.state.productFilter == 'EM' ? this.props.activityLogDetailed.filter(a => a.carrierName != "Allstate") : this.props.activityLogDetailed;
        return (
            <div>
            <Row style={{marginTop: 10}}>
                <Col span={24}>
                    <ActivityLogEntry productFilter={this.state.productFilter} hideButton={false} visible={this.state.visible} view="detailed" onVisibleChange={this.handleEntryVisibleChange} selectedActivityLog={this.state.selectedActivityLog} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} type={this.state.reportFilter} loggedInAgentName={loggedInAgentName} />
                </Col>
            </Row>
            <Row style={{paddingTop: 50}}>
                <Col span={12}>
                    <Title level={4}>AGENT ACTIVITY LOG</Title>
                </Col>
                <Col span={8}>
                    <Radio.Group size="small" defaultValue="All" buttonStyle="solid" onChange={this.handleProductRadioChange} value={this.state.productFilter}>
                        <Radio.Button value="All">All</Radio.Button>
                        <Radio.Button value="Allstate">Allstate</Radio.Button>
                        <Radio.Button value="EM">Expanded Marketplace</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            {
            !this.props.isQuoteLogLoading &&
            <div>
            <Row>
                <Col span={3} style={{display: 'none'}}>
                    <Button type="round" onClick={this.handleOpenSearch} style={{backgroundColor: '#97E45D'}}>
                        <SearchOutlined />Sort Quote Log
                    </Button>
                </Col>
                {
                    this.state.openSearch == true &&
                        <Col span={24}>
                            <Row style={{paddingTop: 5}}>
                                <Col span={20}>
                                    <Row>
                                        <Col span={24}>
                                            <Space direction="vertical">
                                                <Radio.Group defaultValue="a" buttonStyle="solid" onChange={this.handleRadioChange} value={this.state.searchFilter}>
                                                    <Radio.Button value="date">Date Range</Radio.Button>
                                                    <Radio.Button value="name">Prospect Name</Radio.Button>
                                                </Radio.Group>
                                                { this.state.searchFilter == 'date' ?
                                                <Space>
                                                    <Radio.Group defaultValue="thisMonth" buttonStyle="solid" onChange={this.handleDateRadioChange} value={this.state.dateFilter}>
                                                        <Radio.Button value="today">Today</Radio.Button>
                                                        <Radio.Button value="thisWeek">This Week</Radio.Button>
                                                        <Radio.Button value="lastWeek">Last Week</Radio.Button>
                                                        <Radio.Button value="thisMonth">This Month</Radio.Button>
                                                        <Radio.Button value="lastMonth">Last Month</Radio.Button>
                                                    </Radio.Group>
                                                    <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                                                        ranges={{
                                                            Today: [moment(), moment()],
                                                            'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                        }}
                                                        defaultValue={[moment().startOf('week'), moment().endOf('week')]}
                                                        onChange={this.handleDateRangeChange}
                                                        value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                                                        onOpenChange={this.handlePanelChange}
                                                    />
                                                </Space>
                                                :
                                                <Search placeholder="Search" onSearch={value => this.handleSearch(value)} enterButton />
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                }
            </Row>
            <Row style={{paddingTop: 20}}>
                <Col md={16}>
                    <Space>
                        <Radio.Group defaultValue="All" buttonStyle="solid" onChange={this.handleReportRadioChange} value={this.state.reportFilter}>
                            <Radio.Button value="Quoted">Quoted</Radio.Button>
                            <Radio.Button value="Sold">Sold</Radio.Button>
                            <Radio.Button value="All">All</Radio.Button>
                        </Radio.Group>
                        <Button shape="round" onClick={() => this.setState({modalVisible: true})}>
                            <TableOutlined /> Select Columns
                        </Button>
                        <Button shape="round" onClick={clearFilters} style={{color: this.state.filtered ? 'Blue' : 'Black'}}>
                                <FunnelPlotOutlined /> Clear Filters
                        </Button>
                        <Radio.Group size="small" defaultValue="detailed" buttonStyle="solid" onChange={this.handleViewChange} >
                            <Radio.Button value="detailed">Policy View</Radio.Button>
                            <Radio.Button value="summary">Household View</Radio.Button>
                        </Radio.Group>
                    </Space>
                </Col>
                <Col md={8}>
                    <Button shape="round" onClick={() => this.exportToCSV(columns)} style={{position: 'absolute', right: 0}}>
                        <DownloadOutlined /> Export
                    </Button>
                </Col>
            </Row>
            </div>
            }
            <Row>
                <Col span={24} style={{textAlign: "center"}}> 
                    {
                        this.props.isQuoteLogLoading ?
                        <div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading Agent Activity Log</Title></p></div>:
                        <div>
                                <Table
                                    onChange={handleChange} 
                                    dataSource={onlySelf == false ? filterActivityLog : filterActivityLog.filter(a => a.agent == loggedInAgentName)} 
                                    columns={filteredColumns} 
                                    pagination={{ pageSize: 50 }}
                                    rowClassName={(record, index) => ((record.verified === true ? 'VerifiedRow' : ''))} />
                        </div>
                    }
                </Col>
            </Row>
            <Modal 
                width="25%"
                open={this.state.modalVisible}
                title="SELECT COLUMNS"
                onOk={this.handleCloseModal}
                onCancel={this.handleCloseModal}
                footer={[
                    <Row style={{paddingBottom: 10}} >
                        <Col sm={12}>
                            <Button style={{ float: 'left' }} type="primary" shape="round" onClick={this.handleCloseModal}>
                                OK
                            </Button>
                        </Col>
                    </Row>,
                ]}>
                    <Checkbox.Group style={{ width: '100%' }} onChange={this.handleColumnChange} defaultValue={this.state.selectedColumns}>
                        <Row>
                            {columns.map(c =>  
                                <Col span={8} key={c.key}>
                                    <Checkbox value={c.title}>{c.title == "" ? "Edit" : c.title}</Checkbox>
                                </Col>
                            )}
                        </Row>
                    </Checkbox.Group>
            </Modal>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ActivityLogReportDetailed);