import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { actionCreators } from '../store/humbleActions';
import { Card, Button, Input, Col, Row, Typography, Modal, Space } from 'antd'
import Register from './Register';
import { LockOutlined, CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Agreement from './Agreement';
const { Meta } = Card;
const { Text, Title } = Typography;

class Login extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            userName: '',
            loading: false,
            password: '',
            register: 0,
            invalid: false,
            amount: 0,
            forgotPassword: false,
            email: '',
            forgotPasswordResult: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.keyPressed = this.keyPressed.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleSubmitForgotPassword = this.handleSubmitForgotPassword.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps){
        if(this.props.user != prevProps.user){
            if(this.props.user.id == 0){
                this.setState({invalid: true});
            }
            else{
                localStorage.setItem('userId', this.props.user.id);
                localStorage.setItem('status', this.props.user.status);
                localStorage.setItem('agencyId', this.props.user.agencyId);
                //this.props.history.push('/quotelog');
                if (this.props.user.status == 4){
                    this.props.history.push('/dashboard');
                }
                else{
                    this.props.history.push('/activitylog');
                }
            }
        }
        if(this.props.forgotPasswordResult != prevProps.forgotPasswordResult){
            this.setState({forgotPasswordResult: this.props.forgotPasswordResult});
        }
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleForgotPassword = (e) => {
        this.setState({
            forgotPassword: true, forgotPasswordResult: ''
        })
    }
    handleCloseModal = (e) => {
        this.setState({
            forgotPassword: false
        })
    }
    handleSubmit(event) {
        this.setState({loading: true});
        const userName = this.state.userName;
        const password = this.state.password;
        this.props.loginUser(userName, password);
        this.setState({loading: false});
    }
    handleSubmitForgotPassword(event) {
        const email = this.state.email;
        this.props.forgotPassword(email);
        this.setState({loading: false});
    }
    handleRegister(e) {
        this.setState({register: 1, amount: e})
    }
    renderRedirect = () => {
        // if (this.props.user.status == 2) {
        //   return <Redirect to='/agency' />
        // }
        // else if (this.props.user.status == 1){
        //     return <Redirect to='/quotelog' />
        // }
    }
    keyPressed(event) {
        if (event.key === "Enter") {
          this.handleSubmit()
        }
    }
    render() {
        return (
            <div>
            {/* {this.renderRedirect()} */}
            <Row style={{paddingBottom: 30}} >
                <Col sm={{ span: 24 }} lg={{ span: 12, offset: 6 }} style={{textAlign: 'center'}}>
                    <img src="humblelogo.png" alt="Logo" style={{float: "left", height: 65}} />
                </Col>
            </Row>
            {/* <Row>
                <Col sm={12} offset={6} style={{textAlign: 'center'}}>
                    <img className="logo" src="howtowrite.jpg" alt="Logo" style={{height: 300}} />
               
            </Row> */}
            {/* <Row>
                <Col sm={12} offset={6}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden"}}>
                    <Title level={3}>The Humble 100 is a comprehensive platform that utilizes Data Analytics to create a Customized Marketing Plan that is broken down into key data points that allow you to make decisive, data driven decisions that are necessary for holding your Lead Vendors and Sales Agents accountable.</Title>
                    <Title  level={3}>Features and Benefits:</Title>
                    <ul style={{listStyleType: 'none'}}>
                        <li><Text strong><ArrowRightOutlined /> Track and Manage Quoted Prospects</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Track Close Rate</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Track Bundle Rate</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Track Acquisition Cost</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Track Trending New Business Items and Premium</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Create and Develop Your Own Marketing Plan</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Create Agency Goals</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Create Sales Agent Goals</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Leaderboard Gamification</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Sales Target Tracking</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Customized Graphs and Charts</Text></li>
                        <li><Text strong><ArrowRightOutlined /> Free Monthly Group Coaching on how to use your Data to Grow</Text></li>
                    </ul>
                    </Card>
                </Col>
            </Row> */}
            <Row style={{paddingTop: 10}}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12, offset: 6 }} lg={{ span: 12, offset: 6 }}>
                    {
                        this.state.register == 0 ?
                        <Card title="Login" headStyle={{ backgroundColor: '#3D76DD', color: '#fff' }} style={{borderRadius: "20px", overflow: "hidden"}}>
                            <Row>
                                <Col sm={12}>
                                    <Meta description={
                                        <div>
                                        <Row>
                                            <Col sm={24}>
                                                <Input placeholder="Enter Your Username" size="large" name="userName" onChange={e => this.handleChange(e)} /><br />
                                                <Text type="danger" id="emailError" style={{display: 'none'}}>Email is not in the correct format.</Text>
                                                <p style={{paddingTop: '10px'}}><Input.Password placeholder="Password" size="large" name="password" onChange={e => this.handleChange(e)} onKeyPress={e => this.keyPressed(e)} /><br />
                                                <Text type="danger" id="passwordError" style={{display: 'none'}}>Password is not correct.</Text></p>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop: '10px'}}>
                                            <Col sm={12}>
                                                <Button shape="round" type="primary" onClick={e => this.handleSubmit(e)} disabled={this.props.isLoading}> {this.props.isLoading == true ? 'Please Wait' : 'Submit'}
                                                </Button>
                                            </Col>
                                            <Col sm={12} style={{display: "flex"}}>
                                                <Button shape="round" style={{marginLeft: 'auto'}} onClick={e => this.handleForgotPassword(e)}>Forgot Password</Button>
                                                <Modal 
                                                    width="25%"
                                                    visible={this.state.forgotPassword}
                                                    title="Forgot Password"
                                                    onOk={this.handleOk}
                                                    onCancel={this.handleCloseModal}
                                                    footer={[
                                                        this.state.forgotPasswordResult == '' &&
                                                        <Button type="primary" onClick={this.handleSubmitForgotPassword}>
                                                            Submit
                                                        </Button>    
                                                    ]}>
                                                    <div>
                                                        {
                                                            this.state.forgotPasswordResult == '' ?
                                                                <div>
                                                                    <label htmlFor="email">Email</label><br />
                                                                    <Input size="large" placeholder="Email" name="email" onChange={e => this.handleChange(e)} />
                                                                </div>
                                                            :
                                                                this.state.forgotPasswordResult
                                                        }
                                                        
                                                    </div>
                                                </Modal>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop: '10px'}}>
                                            {
                                                (this.state.invalid == true) &&
                                                    <Text type="danger" id="invalid">Invalid Email or Password.  Please Try Again.</Text>
                                            }
                                        </Row>
                                        </div>
                                } />
                                </Col>
                                <Col sm={12} style={{paddingLeft: 30}}>
                                    <Meta description={
                                        <div style={{fontSize: 16}}>
                                        <p><strong>Are you an agency owner?</strong></p>
                                         
                                        <table style={{width: '100%', display: 'none'}}>
                                            <thead style={{ backgroundColor: '#3D76DD' }}>
                                                <tr>
                                                    <th>
                                                        Features
                                                    </th>
                                                    <th style={{textAlign: 'center', fontWeight: 'bold'}}>
                                                        BRONZE
                                                    </th>
                                                    <th style={{textAlign: 'center', fontWeight: 'bold'}}>
                                                        GOLD
                                                    </th>
                                                    <th style={{textAlign: 'center', fontWeight: 'bold'}}>
                                                        PLATINUM
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Quote Log
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined></CheckOutlined>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined></CheckOutlined>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined></CheckOutlined>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Agency Goal Tracker
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined></CheckOutlined>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined></CheckOutlined>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined style={{color: '#4CAD49'}}></CheckOutlined>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Sales Agent Goal Tracker
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined style={{color: '#4CAD49'}}></CheckOutlined>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined style={{color: '#4CAD49'}}></CheckOutlined>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined style={{color: '#4CAD49'}}></CheckOutlined>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Reports
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined style={{color: '#4CAD49'}}></CheckOutlined>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined style={{color: '#4CAD49'}}></CheckOutlined>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Marketing Support
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <CheckOutlined style={{color: '#4CAD49'}}></CheckOutlined>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                       
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Title level={3}>$20 / mo</Title>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Title level={3}>$25 / mo</Title>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Title level={3}>$30 / mo</Title>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                       
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Button style={{backgroundColor: '#4CAD49', color: '#fff'}} onClick={e => this.handleRegister(20)}>Get Started</Button>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Button style={{backgroundColor: '#4CAD49', color: '#fff'}} onClick={e => this.handleRegister(25)}>Get Started</Button>
                                                    </td>
                                                    <td style={{textAlign: 'center'}}>
                                                        <Button style={{backgroundColor: '#4CAD49', color: '#fff'}} onClick={e => this.handleRegister(30)}>Get Started</Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <Button shape="round" type="primary" href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">Sign Up For A Consultation</Button> */}
                                        <Space direction="vertical" style={{paddingBottom: 10, textAlign: 'center'}}>
                                            <Button shape="round" size="large" style={{backgroundColor: '#fff', fontWeight: 'bold', color: 'black'}} href='https://bit.ly/THE-HUMBLE-SIGNUP' target="_blank">SCHEDULE YOUR CONSULTATION</Button>
                                            <Button shape="round" size="large" style={{backgroundColor: '#97E45D', fontWeight: 'bold'}} onClick={() => this.setState({showModal: true})}>PURCHASE SUBSCRIPTION</Button>
                                        </Space>
                                            {/* <Row>
                                                <Col span={8}>
                                                    <Card title="Bronze" headStyle={{ backgroundColor: '#97E45D', textAlign: 'center' }} style={{textAlign: 'center'}}>
                                                        <Title level={3}>$20 / mo</Title>
                                                        <p>Quote Log</p>
                                                        <p>Agency Goals</p>
                                                        <p>Sales Agent Goals</p>
                                                        <p><Button style={{backgroundColor: '#4CAD49', color: '#fff'}}>Get Started</Button></p>
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card title="Gold" headStyle={{ backgroundColor: '#97E45D', textAlign: 'center' }} style={{textAlign: 'center'}}>
                                                        <Title level={3}>$25 / mo</Title>
                                                        <p>Quote Log</p>
                                                        <p>Agency Goals</p>
                                                        <p>Sales Agent Goals</p>
                                                        <p>Reports</p>
                                                        <p><Button style={{backgroundColor: '#4CAD49', color: '#fff'}}>Get Started</Button></p>
                                                    </Card>
                                                </Col>
                                                <Col span={8}>
                                                    <Card title="Platinum" headStyle={{ backgroundColor: '#97E45D', textAlign: 'center' }} style={{textAlign: 'center'}}>
                                                        <Title level={3}>$30 / mo</Title>
                                                        <p>Quote Log</p>
                                                        <p>Agency Goals</p>
                                                        <p>Sales Agent Goals</p>
                                                        <p>Reports</p>
                                                        <p>+Marketing</p>
                                                        <p><Button style={{backgroundColor: '#4CAD49', color: '#fff'}}>Get Started</Button></p>
                                                    </Card>
                                                </Col>
                                            </Row> */}
                                            {/* <Row>
                                                Forgot Password?
                                                <p><a href=""><Icon type="mail"></Icon> Forgot Password</a></p>
                                            </Row> */}
                                        </div>
                                } />
                                </Col>
                            </Row>
                        </Card> :
                        <Register amount={this.state.amount} />
                    }
                </Col>
            </Row>
            <Modal width={1000}
                title="Service Agreement"
                visible={this.state.showModal}
                onOk={this.handleClose}
                onCancel={this.handleClose}
                footer={[
                <Button key="back" href='https://square.link/u/ZAZQGw5t' target="_blank" disabled={this.state.acceptChecks < 3 ? true : false}>
                    Submit
                </Button>,
                <Button key="submit" type="primary" onClick={this.handleClose}>
                    Cancel
                </Button>,
                ]}>
                <Agreement onAcceptClick={this.handleAcceptClicks} />
            </Modal>
            </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);withRouter(Login);