import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { RingProgress, Line, Column, DualAxes } from '@ant-design/plots';
import { actionCreators } from '../store/humbleActions';
import { LineChartOutlined, FunnelPlotOutlined, BarChartOutlined } from '@ant-design/icons';
import { List, Typography, Row, Col, Avatar, DatePicker, Cascader, Space, Button, Select, Radio, Spin, Tooltip, Card, Table, Progress } from 'antd';
import moment from 'moment';
import VendorTypeSummaryChart from './VendorTypeSummaryChart';
const { Title, Text } = Typography;

class Dashboard extends Component {
    constructor(props) {
      super(props);
        this.state = {
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
        }
    }
    
    componentDidMount(){
        const agencyId = localStorage.getItem('agencyId');
        if(agencyId == null){
            this.props.history.push('/');
        }
        const userId = localStorage.getItem("userId");
        const savedFilter = localStorage.getItem('premiumAverageFilter') != null ? localStorage.getItem('premiumAverageFilter') : "premium";
        // this.props.getQuoteLogAveragePremium(agencyId);
        // this.props.getMarketing(agencyId);
        this.props.getActivityLogAgentSummary(agencyId, moment().format("MM/DD/YYYY"), moment().format("MM/DD/YYYY"), "All");
        this.props.getDashboard(agencyId);
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        this.props.getQuoteLogDailyItems(agencyId, dateFrom, dateTo, '', '', 'households');
        this.props.getAgentPremiums(agencyId, dateFrom);
        this.props.getActivityLogZipSummary(agencyId, dateFrom, dateTo, '', '', 'zip', 'all');
        this.props.getQuoteLogPoliciesSummary(agencyId, dateFrom, dateTo, '', '');
        this.props.getMarketing(agencyId, moment().format("MMM YYYY"));
    }
    render() {
        //var monthlyItemGoal = this.props.marketing.monthlyPremium / this.props.marketing.premiumPerItem;
        //var monthlyItemAverage = monthlyItemGoal / this.props.quoteLogAveragePremium.workDaysSoFar;
        const config = {
            data: this.props.dailyItems,
            isGroup: true,
            xField: 'dts',
            yField: 'items',
            seriesField: 'typeItems',
            
        };
        const summaryColumns = [
            {
                title: 'Agent',
                dataIndex: 'agent',
                key:'agentName'
            },
            {
                title: 'Quoted Households',
                dataIndex: 'count',
                align: 'center',
                key: 'count'
            },
            {
                title: title => <strong>Written Items</strong>,
                dataIndex: 'writtenItems',
                align: 'center',
                key: 'writtenItems',
                render: text => <strong>{text}</strong>
            },
            {
                title: title => <strong>Written Premium</strong>,
                dataIndex: 'writtenPremium',
                render: text => <span style={{textAlign: 'center', fontWeight: 'bold'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(text)}</span>,
                align: 'center',
                key: 'writtenPremium'
            },
        ]
        const conicColors = { '0%': 'red', '50%': 'orange', '100%': 'green'};
        const percent = (this.props.dashboard.trendingPremium / this.props.dashboard.monthlyPremiumGoal) * 100;
        const percentItems = (this.props.dashboard.trendingItem / this.props.dashboard.monthlyItemGoal) * 100;
        var yesterdayPremium = this.props.dashboard.writtenPremium - this.props.dashboard.writtenPremiumToday;
        var yesterdayItems = this.props.dashboard.writtenItem - this.props.dashboard.writtenItemsToday;
        var monthlyItemGoal = this.props.marketing.monthlyPremium / this.props.marketing.premiumPerItem;
        var shouldBeAt = (this.props.marketing.monthlyPremium / this.props.dashboard.totalWorkDays) * (this.props.dashboard.workDaysSoFar);
        var shouldBeAtItems = (monthlyItemGoal / this.props.dashboard.totalWorkDays) * (this.props.dashboard.workDaysSoFar);
        console.log(shouldBeAtItems);
        var goalForToday = shouldBeAt - yesterdayPremium;
        var goalForTodayItems = shouldBeAtItems - yesterdayItems;
        console.log(goalForTodayItems);
        var stillNeed = goalForToday - this.props.dashboard.writtenPremiumToday;
        var stillNeedItems = goalForTodayItems - this.props.dashboard.writtenItemsToday;

        var percentage = this.props.dashboard.writtenPremiumToday / goalForToday;
        var percentageItems = this.props.dashboard.writtenItemsToday / goalForTodayItems;
        console.log(percentage);
        return (
            
            <div>
            <Title level={2}>Dashboard</Title>
            <Row>
                {/* <Col sm={6} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc' }}>
                        <Title level={4}>Monthly Premium Goal</Title>
                        <Title level={3}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(this.props.dashboard.monthlyPremiumGoal)}</Title>
                    </Card>
                </Col> */}
                <Col xs={24} sm={12} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Row>
                            <Col md={6} lg={6}>
                                <Title level={4}>Monthly Premium Goal<Title level={4}>{isNaN(this.props.dashboard.monthlyPremiumGoal) ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(this.props.dashboard.monthlyPremiumGoal)}</Title></Title>
                                <Title level={4}>Written Premium<Title level={4}>{isNaN(this.props.dashboard.writtenPremium) ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(this.props.dashboard.writtenPremium)}</Title></Title>
                                <Title level={4}>Trending Premium <Title level={4} style={{color: this.props.dashboard.trendingPremium < this.props.dashboard.monthlyPremiumGoal ? 'Red' : 'Green'}}>{isNaN(this.props.dashboard.trendingPremium) ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(this.props.dashboard.trendingPremium)}</Title></Title>
                            </Col>
                            <Col md={18} lg={18} style={{textAlign: 'center'}}>
                                <Progress width={250} type="circle" percent={percentage * 100} strokeColor="lightgreen" format={() =>
                                <div style={{fontSize: 36}}>
                                    Still Need<br/>
                                    {isNaN(stillNeed) ? 0 : new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(stillNeed < 0 ? 0 : stillNeed)}
                                </div>  
                            }/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {/* <Col sm={6} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Title level={4}>Monthly Item Goal</Title>
                        <Title level={3}>{Math.round(this.props.dashboard.monthlyItemGoal)}</Title>
                    </Card>
                </Col> */}
                <Col xs={24}  sm={12} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Row>
                            <Col md={6} lg={6}>
                                <Title level={4}>Monthly Item Goal<Title level={4}>{isNaN(this.props.dashboard.monthlyItemGoal) ? 0 : Math.round(this.props.dashboard.monthlyItemGoal)}</Title></Title>
                                <Title level={4}>Written Items<Title level={4}>{isNaN(this.props.dashboard.writtenItem) ? 0 : this.props.dashboard.writtenItem}</Title></Title>
                                <Title level={4}>Trending Items<Title level={4} style={{color: this.props.dashboard.trendingItem < this.props.dashboard.monthlyItemGoal ? 'Red' : 'Green'}}>{isNaN(this.props.dashboard.trendingItem) ? 0 : Math.round(this.props.dashboard.trendingItem)}</Title></Title>     
                            </Col>
                            <Col md={18} lg={18} style={{textAlign: 'center'}}>
                                <Progress width={250} type="circle" percent={percentageItems * 100} strokeColor="lightgreen" format={() =>
                                <div style={{fontSize: 36}}>
                                    Still Need<br/>
                                    {isNaN(stillNeed) ? 0 : stillNeedItems < 0 ? 0 : Math.round(stillNeedItems)}
                                </div> } />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Title level={4}>Todays Activity</Title>
                        {this.props.isActivityLoading ?
                        <div style={{textAlign: 'center'}}>
                            <Spin tip="Loading Your Activity Summary Data..."><p></p></Spin>
                        </div>
                        :
                        <Table dataSource={this.props.activityLogAgentSummary} columns={summaryColumns} size="small" pagination={false} summary={pageData => {
                            let totalCount = 0;
                            let totalWrittenItems = 0;
                            let totalWrittenPremium = 0;

                            pageData.forEach(({ count, writtenItems, writtenPremium }) => {
                                totalCount += count;
                                totalWrittenItems += writtenItems;
                                totalWrittenPremium += writtenPremium;
                            });
                            return (
                            <tr key="totalRow">
                                <th>Total</th>
                                <td style={{textAlign: 'center'}} key="totalCount">
                                    <Text>{totalCount}</Text>
                                </td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalWrittenItems">
                                    <Text>{totalWrittenItems}</Text>
                                </td>
                                <td style={{textAlign: 'center', fontWeight: 'bold'}} key="totalWrittenPremium">
                                    <Text>{new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                    }).format(totalWrittenPremium)}</Text>
                                </td>
                            </tr>
                        )
                        }} />
                    }
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={8} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Title level={4}>Sales By Product</Title>
                        <table style={{width: '100%' }}>
                            <thead>
                                <tr style={{backgroundColor: '#1890ff', color: '#fff'}}>
                                    <th>
                                        <strong>PRODUCT</strong>
                                    </th>
                                    <th>
                                        <strong>WRITTEN ITEMS</strong>
                                    </th>
                                    <th>
                                        <strong>WRITTEN PREMIUMS</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.policiesSummary.slice(0, 5).map(item =>
                                    <tr style={{borderBottom: 'solid 1px #CCC'}}>
                                        <td style={{textAlign: 'left'}}>
                                            {item.name}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {item.writtenItems}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(item.premium)}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Card>
                </Col>
                <Col xs={24} sm={8} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Title level={4}>Top Agent Performers</Title>
                        <table style={{width: '100%' }}>
                            <thead>
                                <tr style={{backgroundColor: '#1890ff', color: '#fff'}}>
                                    <th>
                                        <strong>AGENT</strong>
                                    </th>
                                    <th>
                                        <strong>WRITTEN PREMIUMS</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.agentPremiums.slice(0, 5).map(item =>
                                    <tr style={{borderBottom: 'solid 1px #CCC'}}>
                                        <td style={{textAlign: 'left'}}>
                                            {item.agent}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(item.premium)}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Card>
                </Col>
                <Col xs={24} sm={8} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Title level={4}>Top Zip Codes</Title>
                        <table style={{width: '100%' }}>
                            <thead>
                                <tr style={{backgroundColor: '#1890ff', color: '#fff'}}>
                                    <th>
                                        <strong>ZIP CODE</strong>
                                    </th>
                                    <th>
                                        <strong>CITY</strong>
                                    </th>
                                    <th>
                                        <strong>WRITTEN HOUSEHOLDS</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.activityLogZipSummary.slice(0, 5).map(item =>
                                    <tr style={{borderBottom: 'solid 1px #CCC'}}>
                                        <td style={{textAlign: 'left'}}>
                                            {item.zip}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {item.city}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            {item.writtenHouseholds}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={12} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Title level={4}>Daily Households</Title>
                        <Line {...config} />
                    </Card>
                </Col>
                <Col xs={24} sm={12} style={{padding: 5}}>
                    <Card style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '5px 5px 5px #ccc'}}>
                        <Title level={4}>Marketing Bucket</Title>
                        <VendorTypeSummaryChart dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} />
                    </Card>
                </Col>
            </Row>
            </div>
        );
    }
    
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Dashboard);