import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Row, Col, Table, Collapse, Typography, Modal, Spin, DatePicker, Select, Button, Checkbox, Space, Cascader, Radio } from 'antd';
import { FunnelPlotOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import VendorTypeSummaryChart from './VendorTypeSummaryChart';
const { Panel } = Collapse;
const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

class ActivityLogLeadSourceSummary extends Component {
    constructor(props) {
      super(props);
        this.state = {
            agent: null,
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            selectedAgents: null,
            modalVisible: false,
            filteredVendorSummary: [],
            summary: '',
            filtered: false,
            selectedLeadSource: null,
            arrLeadSource: [],
            selectedValues: [],
            selectedReferringAgent: null,
            productFilter: "all"
        }
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleSelectedAgentChange = this.handleSelectedAgentChange.bind(this);
        this.handleLeadSourceChange = this.handleLeadSourceChange.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.handleReferringAgentChange = this.handleReferringAgentChange.bind(this);
        this.myRef = React.createRef();
        this.exportToCSV = this.exportToCSV.bind(this);
        this.convertArrayOfObjectsToCSV = this.convertArrayOfObjectsToCSV.bind(this);
        this.handleProductRadioChange = this.handleProductRadioChange.bind(this);
        
        //this.handleFilter = this.handleFilter.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        if(agencyId == null){
            this.props.history.push('/');
        }
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getAgent(agencyId);
        this.props.getLeadSource(agencyId);
        this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, '', '', 'all');
        this.props.getVendorTypes();
        this.props.getTypeVendorTypes(agencyId);
        this.props.getTypeVendorTypeLeadSources(agencyId);
        this.props.getReferringAgent(agencyId);
    }

    handleDateRangeChange(dates, dateStrings) {
        const dateFrom = dateStrings[0];
        const dateTo = dateStrings[1];
        const agencyId = localStorage.getItem('agencyId');
        const agent = this.state.agent == null ? '' : this.state.agent;
        const referringAgent = this.state.selectedReferringAgent == null ? '' : this.state.selectedReferringAgent;
        if(dateFrom != ''){
            this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, agent, referringAgent, 'all');
        }
        this.setState({
            dateFrom: dateFrom,
            dateTo: dateTo, filtered: true, filteredVendorSummary: []
        });
    }

    handleAgentChange = (e) => {
        this.setState({agent: e});
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const referringAgent = this.state.selectedReferringAgent == null ? '' : this.state.selectedReferringAgent;
        const productFilter = this.state.productFilter;
        this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, e, referringAgent, productFilter);
    }

    handleOpenModal = () => {
        this.setState({ modalVisible: true });
    }

    handleCloseModal = () => {
        this.setState({
            modalVisible: false,
        });
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const selectedAgents = this.state.selectedAgents;
        const referringAgent = this.state.selectedReferringAgent == null ? '' : this.state.selectedReferringAgent;
        const productFilter = this.state.productFilter;
        this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, selectedAgents, referringAgent, productFilter);
    }

    handleSelectedAgentChange(value){
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const referringAgent = this.state.selectedReferringAgent == null ? '' : this.state.selectedReferringAgent;
        const productFilter = this.state.productFilter;
        this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, value, referringAgent, productFilter);
        this.setState({agent: value, filtered: true, filteredVendorSummary: []});
    }

    handlePanelChange = (open) => {
        if(open){
            this.setState({dateFrom: null, dateTo: null})
        }
    }

    handleLeadSourceChange(value){
        var leadSourceList = '';
        var arrLeadSource = [];
        for (var i=0; i < value.length; i++){
            if(value[i].length > 1){
                leadSourceList += leadSourceList == '' ? value[i][1] : ',' + value[i][1];
                arrLeadSource.push(value[i][1]);
            } else {
                var fullList = this.props.typeVendorTypesLeadSources.filter(l => l.value == value[i][0]);
                var children = fullList[0].children;
                for (var x=0; x < children.length; x++){
                    leadSourceList += leadSourceList == '' ? children[x].value : ',' + children[x].value;
                    arrLeadSource.push(children[x].value);
                }
            }
        }
        const filteredSummary = this.props.activityLogAgentSummary.filter((el) => {
            return arrLeadSource.some((f) => {
                return f == el.typeQuoteLogLeadSourceId;
            });
        });
        
        this.setState({filteredVendorSummary: filteredSummary, filtered: true, arrLeadSource: arrLeadSource, selectedValues: value});
    }
    handleClearFilters(){
        const agencyId = localStorage.getItem('agencyId');
        const dateFrom = moment().startOf('month').format("MM/DD/YYYY");
        const dateTo = moment().endOf('month').format("MM/DD/YYYY");
        this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, '', '', 'all');
        
        this.setState({filteredVendorSummary: [], dateFrom: dateFrom, dateTo: dateTo, filtered: false, agent: null, arrLeadSource: [], selectedValues: [], selectedReferringAgent: null});
    }
    exportToCSV = (e) => {
        e.preventDefault();
        var data, filename, link;
        data = this.state.selectedLeadSource == null ? this.props.activityLogAgentSummary : this.props.activityLogAgentSummary.filter(v => v.vendorType == this.state.selectedLeadSource)
        var csv = this.convertArrayOfObjectsToCSV(data, this.props.leadSource);
        if (csv == null)
          return;
    
        filename = 'LeadSourceActivitySummary' + this.state.dateFrom + '-' + this.state.dateTo + '.csv';
    
        var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
    
        if (navigator.msSaveBlob)
        { // IE 10+
          navigator.msSaveBlob(blob, filename)
        }
        else
        {
          var link = document.createElement("a");
          if (link.download !== undefined)
          {
            // feature detection, Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style = "visibility:hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
    
        //console.log(arrOrders);
      }
    
    convertArrayOfObjectsToCSV = (args, leadSource) => {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    
        data = args || null;
        if (data == null || !data.length) {
            return null;
        }
    
        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';
    
        keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;
                if(key == 'vendor'){
                    var leadSourceName = leadSource.filter(l => l.id == item[key]).length == 0 ? item[key] : leadSource.find(l => l.id == item[key]).name
                    result += leadSourceName;
                }else{
                    result += item[key];
                }
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    };
    handleReferringAgentChange = (e) => {
        console.log(e);
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const agent = this.state.agent == null ? '' : this.state.agent;
        const productFilter = this.state.productFilter;
        this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, agent, e, productFilter);
        this.setState({filtered: true, filteredVendorSummary: [], selectedReferringAgent: e});
        this.setState({selectedReferringAgent: e});
        
    }
    handleProductRadioChange(e) {
        const dateFrom = this.state.dateFrom;
        const dateTo = this.state.dateTo;
        const agencyId = localStorage.getItem('agencyId');
        const referringAgent = this.state.selectedReferringAgent == null ? '' : this.state.selectedReferringAgent;
        const agent = this.state.agent == null ? '' : this.state.agent;
        this.props.getActivityLogLeadSourceSummary(agencyId, dateFrom, dateTo, agent, referringAgent, e.target.value);
        this.setState({
            productFilter: e.target.value
        });
    }
    render() {
        const filterLeadSource = [];
        this.props.leadSource.filter(l => l.inactive == false).map (ls =>
            filterLeadSource.push({
                text: ls.name,
                value: ls.id
            })
        );
        
        const vendorSummary = this.state.arrLeadSource.length > 0 ? this.props.activityLogLeadSourceSummary.filter((el) => {
            return this.state.arrLeadSource.some((f) => {
                return f == el.typeQuoteLogLeadSourceId;
            });
        }) : this.props.activityLogLeadSourceSummary;

        let totalQuotedHouseholds = 0;
        let totalWrittenHouseholds = 0;
        let totalWrittenItems = 0;
        let totalQuotedItems = 0;
        let totalWrittenPremium = 0;
        let totalQuotedPolicies = 0;
        let totalWrittenPolicies = 0;
        console.log(vendorSummary);
        vendorSummary &&
            vendorSummary.forEach(({ quotedHouseholds, writtenHouseholds, writtenItems, quotedItems, writtenPremium, quotedPolicies, writtenPolicies }) => {
                totalQuotedHouseholds += quotedHouseholds;
                totalWrittenHouseholds += writtenHouseholds;
                totalWrittenItems += writtenItems;
                totalQuotedItems += quotedItems;
                totalWrittenPremium += writtenPremium;
                totalQuotedPolicies += quotedPolicies;
                totalWrittenPolicies += writtenPolicies;
            });
            
            const summaryTable = (
                <table width='100%'>
                    <tr style={{backgroundColor: '#3D76DD', fontWeight: 'bold'}}>
                        <th></th>
                        <td style={{textAlign: 'center'}} key="totalCount">
                            <Text style={{color: '#fff'}}>Quoted Households</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                            <Text style={{color: '#fff'}}>Quoted Policies</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedItems">
                            <Text style={{color: '#fff'}}>Quoted Items</Text>
                        </td>
                        
                        <td style={{textAlign: 'center'}} key="totalWrittenHouseholds">
                            <Text style={{color: '#fff'}}>Written Households</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPolicies">
                            <Text style={{color: '#fff'}}>Written Policies</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenItems">
                            <Text style={{color: '#fff'}}>Written Items</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPremium">
                            <Text style={{color: '#fff'}}>Written Premium</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalCloseRate">
                            <Text style={{color: '#fff'}}>Close Rate</Text>
                        </td>
                    </tr>
                    <tr ref={this.myRef} key="totalRow" style={{fontWeight: 'bold'}}>
                        <th>Total</th>
                        <td style={{textAlign: 'center'}} key="totalCount">
                            <Text>{totalQuotedHouseholds}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedPolicies">
                            <Text>{totalQuotedPolicies}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalQuotedItems">
                            <Text>{totalQuotedItems}</Text>
                        </td>
                        
                        <td style={{textAlign: 'center'}} key="totalWrittenHouseholds">
                            <Text>{totalWrittenHouseholds}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPolicies">
                            <Text>{totalWrittenPolicies}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenItems">
                            <Text>{totalWrittenItems}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalWrittenPremium">
                            <Text>{new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                            }).format(totalWrittenPremium)}</Text>
                        </td>
                        <td style={{textAlign: 'center'}} key="totalCloseRate">
                            <Text>
                            {new Intl.NumberFormat("en-US", {
                                style: "percent",
                                maximumFractionDigits: 2
                            }).format(totalWrittenPolicies / totalQuotedPolicies)}
                            </Text>
                        </td>
                    </tr>
                </table>
        )
        const summaryVendorColumns = [
            {
                title: 'Lead Source',
                dataIndex: 'leadSource',
                key: 'leadSource',
                sorter: (a, b) => ('' + a.vendor).localeCompare(b.vendor),
                render: (text, record) => record.requote ? text + " / REQUOTE / AGED LEAD" : text, //this.props.leadSource.filter(l => l.id == text).length == 0 ? text : this.props.leadSource.find(l => l.id == text).name,
                filters: filterLeadSource,
                filterMultiple: true,
                onFilter: (value, record) => record.vendor.indexOf(value) === 0,
            },
            {
                title: 'Marketing Bucket',
                dataIndex: 'typeName',
                key: 'typeName',
                sorter: (a, b) => ('' + a.vendorType).localeCompare(b.vendorType),
            },
            {
                title: 'Quoted Households',
                dataIndex: 'quotedHouseholds',
                key: 'quotedHouseholds',
                render: text => <span style={{textAlign: 'center'}}>{text}</span>,
                align: 'center',
                sorter: (a, b) => a.quotedHouseholds - b.quotedHouseholds,
            },
            {
                title: 'Quoted Policies',
                dataIndex: 'quotedPolicies',
                align: 'center',
                sorter: (a, b) => a.quotedPolicies - b.quotedPolicies,
            },
            {
                title: 'Quoted Items',
                dataIndex: 'quotedItems',
                key: 'quotedItems',
                render: text => <span style={{textAlign: 'center'}}>{text}</span>,
                align: 'center',
                sorter: (a, b) => a.quotedItems - b.quotedItems,
            },
            {
                title: 'Written Households',
                dataIndex: 'writtenHouseholds',
                key: 'vendorWrittenHouseholds',
                align: 'center',
                sorter: (a, b) => a.writtenHouseholds - b.writtenHouseholds,
            },
            {
                title: 'Written Policies',
                dataIndex: 'writtenPolicies',
                align: 'center',
                sorter: (a, b) => a.writtenPolicies - b.writtenPolicies,
            },
            {
                title: 'Written Items',
                dataIndex: 'writtenItems',
                key: 'writtenItems',
                align: 'center',
                sorter: (a, b) => a.writtenItems - b.writtenItems,
            },
            {
                title: 'Written Premium',
                dataIndex: 'writtenPremium',
                render: text => <span style={{textAlign: 'center'}}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(text)}</span>,
                align: 'center',
                sorter: (a, b) => a.writtenPremium - b.writtenPremium,
            },
            {
                title: 'Close Ratio',
                dataIndex: 'closeRatio',
                key: 'vendorCloseRatio',
                align: 'center',
                sorter: (a, b) => parseFloat(a.closeRatio) - parseFloat(b.closeRatio),
            },
            {
                title: 'Written Bundle Ratio',
                dataIndex: 'writtenBundlesRate',
                key: 'vendorWrittenBundlesRate',
                align: 'center',
                sorter: (a, b) => parseFloat(a.closeRatio) - parseFloat(b.closeRatio),
            },
        ];
        
        return (
            
            <div>
                <Row>
                    <Col sm={8}>
                        <Title level={3}>LEAD SOURCE ACTIVITY SUMMARY</Title>
                    </Col>
                    <Col span={16} style={{textAlign: "center"}}>
                        <VendorTypeSummaryChart dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} />
                    </Col>
                </Row>
                {this.props.isQuoteLogLoading ?<div style={{textAlign: 'center'}}> <Spin></Spin><p><Title level={3}>Loading report.  Please do not click the refresh button...</Title></p></div>:
                <div>
                <Row>
                    <Col sm={4}>
                        <RangePicker format={'MM/DD/YYYY'} allowEmpty={true}
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                            defaultValue={[moment().startOf('month'), moment().endOf('month')]}
                            value={this.state.dateFrom == null ? [] : [moment(this.state.dateFrom), moment(this.state.dateTo)]}
                            onChange={this.handleDateRangeChange}
                            onOpenChange={this.handlePanelChange}
                        />
                    </Col>
                    <Col sm={4}>
                        <Select
                            virtual={false}
                            style={{ width: '90%' }}
                            dropdownStyle={{ minWidth: "20%" }}
                            placeholder={<strong style={{color: 'black'}}>Select Agent</strong>}
                            name="agents"
                            value={this.state.agent}
                            onChange={this.handleSelectedAgentChange}>
                                <Option key='' value=''></Option>
                            {
                                this.props.agent.filter(a => a.status != "5").map(a => 
                                    <Option key={a.firstName} value={a.firstName}>{a.firstName}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col sm={4}>
                        <Cascader
                            style={{ width: '90%', color: 'Blue' }}
                            options={this.props.typeVendorTypesLeadSources}
                            onChange={this.handleLeadSourceChange}
                            multiple
                            maxTagCount="responsive"
                            placeholder={<strong style={{color: 'black'}}>Select Lead Source</strong>}
                            value={this.state.selectedValues}
                        />
                    </Col>
                    <Col sm={4}>
                        <Select
                            virtual={false}
                            style={{ width: '90%' }}
                            dropdownStyle={{ minWidth: "20%" }}
                            placeholder={<strong style={{color: 'black'}}>Select Referring Agent</strong>}
                            name="referringAgent"
                            value={this.state.selectedReferringAgent}
                            onChange={this.handleReferringAgentChange}>
                                <Option key="" value="">{"All"}</Option>
                            {
                                this.props.referringAgent.filter(a => !a.inactive).map(a => 
                                    <Option key={a.id} value={a.id}>{a.name}</Option>
                                )
                            }
                            
                        </Select>
                    </Col>
                    <Col sm={4}>
                        <Button shape="round" onClick={() => this.handleClearFilters()} style={{color: this.state.filtered ? 'Blue' : 'Black'}}>
                            <FunnelPlotOutlined /> Clear Filters
                        </Button>
                    </Col>
                    <Col sm={4}>
                        <Button shape="round" onClick={this.exportToCSV} style={{position: 'absolute', right: 0}}>
                            <DownloadOutlined /> Export
                        </Button>
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <Radio.Group size="small" defaultValue="All" buttonStyle="solid" onChange={this.handleProductRadioChange} value={this.state.productFilter}>
                            <Radio.Button value="all">All</Radio.Button>
                            <Radio.Button value="allstate">Allstate</Radio.Button>
                            <Radio.Button value="em">Expanded Marketplace</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row style={{paddingTop: 10}}>
                    <Col span={24}>
                        <p>{summaryTable}</p>
                        <Table dataSource={vendorSummary} columns={summaryVendorColumns} pagination={false}></Table>
                    </Col>
                    
                </Row>
                
                <Modal 
                width="25%"
                visible={this.state.modalVisible}
                title="SELECT AGENTS"
                onOk={this.handleCloseModal}
                onCancel={this.handleCloseModal}
                footer={[
                    <Row style={{paddingBottom: 10}} >
                        <Col sm={12}>
                            <Button style={{ float: 'left' }} type="primary" shape="round" onClick={this.handleCloseModal}>
                                OK
                            </Button>
                        </Col>
                    </Row>,
                ]}>
                    <Checkbox.Group style={{ width: '100%' }} onChange={this.handleSelectedAgentChange}>
                        <Row>
                            {this.props.agent.map(a => 
                                <Col span={8} key={a.firstName}>
                                    <Checkbox value={a.firstName}>{a.firstName}</Checkbox>
                                </Col>
                            )}
                        </Row>
                    </Checkbox.Group>
            </Modal>
            </div>}
        </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ActivityLogLeadSourceSummary);                   