import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Card, Input, Row, Col, List, Typography, Select, Button, Radio, Checkbox } from 'antd';
import { DeleteOutlined, SaveOutlined, PlusCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import "video-react/dist/video-react.css";
import { Player, BigPlayButton, ControlBar } from 'video-react';
const {Title, Text} = Typography
const {Option} = Select

class Education extends Component {
    constructor(props) {
      super(props);
        this.state = {
            videoSrc: ''
        }
        this.handleSourceChange = this.handleSourceChange.bind(this);
    }

    componentDidMount() {

    }
    handleSourceChange(e) {
        this.setState({videoSrc: e})
        const userId = localStorage.getItem('userId');
    }
    render() {
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={8} style={{paddingLeft: 16}}>
                            <Row>
                                <Col span={24}>
                                    <Card title="Humble v2.0" style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }}>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="HUMBLE 20 EDGE CLEARING CACHE.mp4" onClick={e => this.handleSourceChange("HUMBLE 20 EDGE CLEARING CACHE.mp4")}><PlayCircleOutlined />  HUMBLE 20 EDGE CLEARING CACHE</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="HUMBLE 20 GOOGLE CLEARING CACHE.mp4" onClick={e => this.handleSourceChange("HUMBLE 20 GOOGLE CLEARING CACHE.mp4")}><PlayCircleOutlined />  HUMBLE 20 GOOGLE CLEARING CACHE</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card title="Settings" style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }}>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="ADDING USERS.mp4" onClick={e => this.handleSourceChange("ADDING USERS.mp4")}><PlayCircleOutlined />  ADDING USERS</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="AGENCY GOALS.mp4" onClick={e => this.handleSourceChange("AGENCY GOALS.mp4")}><PlayCircleOutlined />  AGENCY GOALS</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="SALES PRODUCER GOALS.mp4" onClick={e => this.handleSourceChange("SALES PRODUCER GOALS.mp4")}><PlayCircleOutlined />  SALES PRODUCER GOALS</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="MARKETING BUCKETS.mp4" onClick={e => this.handleSourceChange("MARKETING BUCKETS.mp4")}><PlayCircleOutlined />  MARKETING BUCKETS</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="LEAD SOURCES.mp4" onClick={e => this.handleSourceChange("LEAD SOURCES.mp4")}><PlayCircleOutlined />  LEAD SOURCES</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="REFERRING AGENTS.mp4" onClick={e => this.handleSourceChange("REFERRING AGENTS.mp4")}><PlayCircleOutlined />  REFERRING AGENTS</Button>
                                            </Col>
                                        </Row>
                                        
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card title="Activity Log" style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }}>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="ACTIVITY LOG OVERVIEW.mp4" onClick={e => this.handleSourceChange("ACTIVITY LOG OVERVIEW.mp4")}><PlayCircleOutlined />  ACTIVITY LOG OVERVIEW</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card title="Uploads" style={{borderColor: '#1890ff', borderRadius: "20px", overflow: "hidden", boxShadow: '10px 5px 5px #ccc', marginBottom: 10 }}>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="DOWNLOADING ALLSTATE SERIOUS QUOTES.mp4" onClick={e => this.handleSourceChange("DOWNLOADING ALLSTATE SERIOUS QUOTES.mp4")}><PlayCircleOutlined />  DOWNLOADING ALLSTATE SERIOUS QUOTES</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="SERIOUS QUOTE EDITING CSV.mp4" onClick={e => this.handleSourceChange("SERIOUS QUOTE EDITING CSV.mp4")}><PlayCircleOutlined />  SERIOUS QUOTE EDITING CSV</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="SERIOUS QUOTES UPLOAD.mp4" onClick={e => this.handleSourceChange("SERIOUS QUOTES UPLOAD.mp4")}><PlayCircleOutlined />  SERIOUS QUOTES UPLOAD</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="UPLOADED QUOTES OVERVIEW.mp4" onClick={e => this.handleSourceChange("UPLOADED QUOTES OVERVIEW.mp4")}><PlayCircleOutlined />  UPLOADED QUOTES OVERVIEW</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="UPLOADED QUOTES EXACT MATCHES.mp4" onClick={e => this.handleSourceChange("UPLOADED QUOTES EXACT MATCHES.mp4")}><PlayCircleOutlined />  UPLOADED QUOTES EXACT MATCHES</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="UPLOADED QUOTES SUGGESTED MATCHES.mp4" onClick={e => this.handleSourceChange("UPLOADED QUOTES SUGGESTED MATCHES.mp4")}><PlayCircleOutlined />  UPLOADED QUOTES SUGGESTED MATCHES</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Button type="link" name="NEW BUSINESS UPLOAD.mp4" onClick={e => this.handleSourceChange("NEW BUSINESS UPLOAD.mp4")}><PlayCircleOutlined />  NEW BUSINESS UPLOAD</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} style={{paddingLeft: 16}}>
                            <Player src={this.state.videoSrc} autoPlay>
                                <ControlBar autoHide={false}  />
                            </Player>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Education);