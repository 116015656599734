import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Modal, Button, Typography, Tooltip } from 'antd';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from './IdleModal'
import { withRouter } from "react-router-dom";
import { BookOutlined, TrophyOutlined, TeamOutlined, PhoneOutlined, DollarOutlined, IdcardFilled, FormOutlined, IdcardOutlined, FileDoneOutlined, HomeOutlined, CloseCircleFilled, AreaChartOutlined, SettingOutlined, DashboardOutlined, UploadOutlined, ToolOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const {Text} = Typography

// export default props => (
    class Master extends React.Component {
        constructor(props){
            super(props)
    
            this.state = {
                timeout:1000 * 4200 * 1,
                showModal: false,
                userLoggedIn: false,
                isTimedOut: false,
                showModalSupport: false,
                collapsed: true,
                showModalRefresh: false
            }
    
            this.idleTimer = null
            this.onAction = this._onAction.bind(this);
            this.onActive = this._onActive.bind(this);
            this.onIdle = this._onIdle.bind(this);
            this.handleClose = this.handleClose.bind(this);
            this.handleLogout = this.handleLogout.bind(this);
            this.handleCloseSupport = this.handleCloseSupport.bind(this);
            this.handleModalRefresh = this.handleModalRefresh.bind(this);
        }
        componentDidMount() {
            // const latestVersion = localStorage.getItem('latestVersion');
            // if(latestVersion == null) {
            //     this.setState({showModalRefresh: true});
            // }
        }
        handleClose() {
            this.setState({showModal: false})
        }
        handleCloseSupport() {
            this.setState({showModalSupport: false})
        }
        handleModalRefresh() {
            this.setState({showModalRefresh: false});
            localStorage.setItem('latestVersion', "true");
            window.location.reload();
        }
        handleLogout() {
            localStorage.removeItem("agencyId");
            this.setState({showModal: false});
            this.props.history.push('/');
        }

        _onAction(e) {
          //console.log('user did something', e)
          this.setState({isTimedOut: false})
        }
       
        _onActive(e) {
          //console.log('user is active', e)
          this.setState({isTimedOut: false})
        }
       
        _onIdle(e) {
          //console.log('user is idle', e)
          const isTimedOut = this.state.isTimedOut
          if (isTimedOut) {
            localStorage.removeItem("agencyId");
            this.props.history.push('/');
          } else {
            this.setState({showModal: true})
            this.idleTimer.reset();
            this.setState({isTimedOut: true})
          }
        }
        render(){
            const userStatus = localStorage.getItem('status');
            const agencyId = localStorage.getItem('agencyId');
            return(
                <div>
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={this.onIdle}
                        onAction={this.onAction}
                        debounce={250}
                        timeout={this.state.timeout} />
                    <Layout style={{backgroundColor: '#fff'}}>
                        <Sider 
                            trigger={null}
                            style={{height: '100vh', backgroundColor: '#fff', position: 'fixed', top: 0, bottom: 0}} 
                            collapsible 
                            collapsed={this.state.collapsed} 
                            onCollapse={(value) => this.setState({collapsed: value})}
                            // onMouseEnter={e => {
                            //     if (this.state.collapsed) {
                            //       this.setState({
                            //         collapsed: false
                            //       });
                            //     }
                            //   }}
                            // onMouseLeave={e => {
                            //     if (!this.state.collapsed) {
                            //       this.setState({
                            //         collapsed: true
                            //       });
                            //     }
                            //   }}
                            >
                                <div style={{textAlign: 'center'}}>
                                    <Tooltip title="Open Menu">
                                        <Button type="link"  onClick={() => this.setState({collapsed: !this.state.collapsed})} icon={this.state.collapsed == false ? <MenuFoldOutlined style={{fontSize: '20px'}} /> : <MenuUnfoldOutlined style={{fontSize: '20px'}} />} />
                                    </Tooltip>
                                </div>
                            <Menu style={{backgroundColor: '#fff'}} 
                                //onClick={(e) => setSelectedMenuItem(e.key)}
                                mode="vertical"
                                defaultSelectedKeys={['1']}
                                items={[
                                    {
                                        key: '1',
                                        icon: <DashboardOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Link to={'/Dashboard'} style={{fontSize: '20px'}}>Dashboard</Link>

                                        ),
                                    },
                                    {
                                        key: '2',
                                        icon: <SettingOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Link to={'/Settings'} style={{fontSize: '20px'}}>Settings</Link>
                                        ),
                                    },
                                    {
                                        key: '3',
                                        icon: <FormOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Link to={'/activitylog'} style={{fontSize: '20px'}}>Activity Log</Link>
                                        ),
                                    },
                                    {
                                        key: '4',
                                        icon: <FileDoneOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Link to={'/onboarding'} style={{fontSize: '20px'}}>Onboarding</Link>
                                        ),
                                    },
                                    {
                                        key: '5',
                                        icon: <AreaChartOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Text style={{fontSize: '20px'}}>Reports</Text>
                                        ),
                                        children: [
                                            {
                                                key: '5-1',
                                                label: (
                                                    <Link to={'/activitylogleadsourcesummary'}>Lead Source Performance</Link>
                                                ),
                                            },
                                            {
                                                key: '5-2',
                                                label: (
                                                    <Link to={'/activitylogzipsummary'}>Zip Code Performance</Link>
                                                ),
                                            },
                                            {
                                                key: '5-3',
                                                label: (
                                                    <Link to={'/policysummary'}>Product Performance</Link>
                                                ),
                                            },
                                            {
                                                key: '5-4',
                                                label: (
                                                    <Link to={'/currentcarrier'}>Current Carrier Performance</Link>
                                                ),
                                            },
                                            {
                                                key: '5-5',
                                                label: (
                                                    <Link to={'/referringagentsummary'}>Referring Agent Summary</Link>
                                                ),
                                            },
                                            {
                                                key: '5-6',
                                                label: (
                                                    <Link to={'/graphs'}>Graphs and Charts</Link>
                                                ),
                                            },
                                            {
                                                key: '5-7',
                                                label: (
                                                    <Link to={'/dnqreport'}>Did Not Qualify</Link>
                                                ),
                                            },
                                            {
                                                key: '5-8',
                                                label: 'Data Validation',
                                                type: 'group',
                                                children: [
                                                  {
                                                    key: '5-8-1',
                                                    label: (
                                                        <Link to={'/seriousuploadreport'}>Quotes</Link>
                                                    ),
                                                  },
                                                  {
                                                    key: '5-8-2',
                                                    label: (
                                                        <Link to={'/newbusinessuploadreport'}>New Business</Link>
                                                    ),
                                                  },
                                                ],
                                              },
                                        ]
                                    },
                                    {
                                        key: '6',
                                        icon: <PhoneOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Link to={'/marketing'} style={{fontSize: '20px'}}>Marketing</Link>
                                        ),
                                    },
                                    {
                                        key: '7',
                                        icon: <UploadOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Text style={{fontSize: '20px'}}>Uploads</Text>
                                        ),
                                        style: {display: userStatus == 4 ? 'inline' : 'none'},
                                        children: [
                                            {
                                                key: '7-1',
                                                label: (
                                                    <Link to={'/seriousupload'}>Quote Upload</Link>
                                                ),
                                            },
                                            {
                                                key: '7-2',
                                                label: (
                                                    <Link to={'/newbusinessupload'}>New Business Upload</Link>
                                                ),
                                            },
                                            {
                                                key: '7-3',
                                                label: (
                                                    <Link to={'/zipupload'}>Zip Code Upload</Link>
                                                ),
                                            },
                                            {
                                                key: '7-4',
                                                label: (
                                                    <Link to={'/uploadeducation'}>Your Agency Education Upload</Link>
                                                ),
                                            },
                                        ]
                                    },
                                    {
                                        key: '8',
                                        icon: <BookOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            <Text style={{fontSize: '20px'}}>Education</Text>
                                        ),
                                        children: [
                                            {
                                                key: '8-1',
                                                label: (
                                                    <Link to={'/education'}>Humble100 Education</Link>
                                                ),
                                            },
                                            {
                                                key: '8-2',
                                                label: (
                                                    <Link to={'/agencyedumaterials'}>Your Agency Education</Link>
                                                ),
                                            },
                                        ]
                                    },
                                    {
                                        key: '9',
                                        icon: <ToolOutlined style={{fontSize: '20px'}} />,
                                        label: (
                                            // <Button type="link" style={{fontSize: '20px', marginLeft: 0}} onClick={() => this.setState({showModalSupport: true})}>Support</Button>
                                            <Link style={{fontSize: '20px'}} onClick={() => this.setState({showModalSupport: true})}>Support</Link>
                                        ),
                                    },
                                    {
                                        key: '10',
                                        icon: <CloseCircleFilled style={{fontSize: '20px', color: 'Red'}} />,
                                        //style: {position: 'absolute', bottom: 10},
                                        label: (
                                            <Link to={'/'} style={{fontSize: '20px', color: 'Red'}}>Log Out</Link>
                                        ),
                                    },
                                ]}
                            />
                        </Sider>
                        <Layout style={{marginLeft: this.state.collapsed ? 70 : 200}}>
                            <Header style={{backgroundColor: '#fff'}}>
                                <img src="humblelogo.png" alt="Logo" style={{float: "left", height: 65}} />
                                {
                                    // userStatus == 4 ? //Admin
                                    // <Menu mode="horizontal" style={{ background: '#fff' }}>
                                    //     <Menu.Item key="1" style={{fontSize: '16px'}}><Link to={'/Dashboard'}><DashboardOutlined style={{fontSize: '20px'}} /> Dashboard</Link></Menu.Item>
                                    //     <Menu.Item key="2" style={{fontSize: '16px'}}><Link to={'/Settings'}><SettingOutlined style={{fontSize: '20px'}} /> Settings</Link></Menu.Item>
                                    //     <Menu.Item key="7" style={{fontSize: '16px'}}><Link to={'/quotelog'}><FormOutlined style={{fontSize: '20px'}} /> Activity Log</Link></Menu.Item>
                                    //     <Menu.Item key="10" style={{fontSize: '16px'}}><Link to={'/onboarding'}><FileDoneOutlined style={{fontSize: '20px'}} /> Onboarding</Link></Menu.Item>
                                    //     <SubMenu key="SubMenu" style={{fontSize: '16px'}} title={<span><AreaChartOutlined style={{fontSize: '20px'}} /> Reports</span>}>
                                    //         <Menu.Item key="sub:1"><Link to={'/vendoractivitysummary'}>Lead Source Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:3"><Link to={'/zipcodesummary'}>Zip Code Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:5"><Link to={'/policysummary'}>Product Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:8"><Link to={'/currentcarrier'}>Current Carrier Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:9"><Link to={'/referringagentsummary'}>Referring Agent Summary</Link></Menu.Item>
                                    //         <Menu.Item key="sub:6"><Link to={'/graphs'}>Graphs and Charts</Link></Menu.Item>
                                    //         <Menu.Item key="sub:7"><Link to={'/dnqreport'}>Did Not Qualify</Link></Menu.Item>
                                    //         <Menu.ItemGroup title="Data Validation">
                                    //             <Menu.Item key="val:1">
                                    //                 <Link to={'/seriousuploadreport'}>Quotes</Link>
                                    //             </Menu.Item>
                                    //             <Menu.Item key="val:2">
                                    //                 <Link to={'/newbusinessuploadreport'}>New Business</Link>
                                    //             </Menu.Item>
                                    //         </Menu.ItemGroup>
                                    //     </SubMenu>
                                    //     <Menu.Item key="4" style={{fontSize: '16px'}}><Link to={'/marketing'}><PhoneOutlined style={{fontSize: '20px'}} /> Marketing</Link></Menu.Item>
                                    //     <SubMenu key="Uploads" style={{fontSize: '16px'}} title={<span><UploadOutlined style={{fontSize: '20px'}} /> Uploads</span>}>
                                    //         <Menu.Item key="upload:1"><Link to={'/seriousupload'}>Quote Upload</Link></Menu.Item>
                                    //         <Menu.Item key="upload:2"><Link to={'/newbusinessupload'}>New Business Upload</Link></Menu.Item>
                                    //         <Menu.Item key="upload:4"><Link to={'/zipupload'}>Zip Code Upload</Link></Menu.Item>
                                    //         <Menu.Item key="upload:5"><Link to={'/uploadeducation'}>Your Agency Education Upload</Link></Menu.Item>
                                    //     </SubMenu>
                                    //     <SubMenu key="Education" style={{fontSize: '16px'}} title={<span><BookOutlined style={{fontSize: '20px'}} /> Education</span>}>
                                    //         <Menu.Item key="education:1"><Link to={'/education'}>Humble100 Education</Link></Menu.Item>
                                    //         <Menu.Item key="education:2"><Link to={'/agencyedumaterials'}>Your Agency Education</Link></Menu.Item>
                                    //     </SubMenu>
                                    //     <Menu.Item key="9" style={{fontSize: '16px'}} onClick={() => this.setState({showModalSupport: true})}><ToolOutlined style={{fontSize: '20px'}} /> Support</Menu.Item>
                                    //     <Menu.Item key="8" style={{fontSize: '16px', position: 'absolute', right: 0}} onClick={this.handleLogout}><Link to={'/'} style={{color: 'Red'}}><CloseCircleFilled style={{fontSize: '20px', color: 'Red'}} /> Log Out</Link></Menu.Item>
                                    // </Menu>
                                    // : userStatus == 1 ? //Super User
                                    // <Menu mode="horizontal" style={{ background: '#fff' }}>
                                    //     <Menu.Item key="5" style={{fontSize: '16px'}}><Link to={'/quotelog'}><FormOutlined style={{fontSize: '20px'}} /> Activity Log</Link></Menu.Item>
                                    //     <SubMenu key="SubMenu" style={{fontSize: '16px'}} title={<span><AreaChartOutlined style={{fontSize: '20px'}} /> Reports</span>}>
                                    //         <Menu.Item key="sub:1"><Link to={'/vendoractivitysummary'}>Lead Source Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:3"><Link to={'/zipcodesummary'}>Zip Code Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:5"><Link to={'/policysummary'}>Product Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:8"><Link to={'/currentcarrier'}>Current Carrier Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:9"><Link to={'/referringagentsummary'}>Referring Agent Summary</Link></Menu.Item>
                                    //         <Menu.Item key="sub:6"><Link to={'/graphs'}>Graphs and Charts</Link></Menu.Item>
                                    //         <Menu.Item key="sub:7"><Link to={'/dnqreport'}>Did Not Qualify</Link></Menu.Item>
                                    //         <Menu.ItemGroup title="Data Validation">
                                    //             <Menu.Item key="val:1">
                                    //                 <Link to={'/seriousuploadreport'}>Quotes</Link>
                                    //             </Menu.Item>
                                    //             <Menu.Item key="val:2">
                                    //                 <Link to={'/newbusinessuploadreport'}>New Business</Link>
                                    //             </Menu.Item>
                                    //         </Menu.ItemGroup>
                                    //     </SubMenu>
                                    //     <SubMenu key="Education" style={{fontSize: '16px'}} title={<span><BookOutlined style={{fontSize: '20px'}} /> Education</span>}>
                                    //         <Menu.Item key="education:1"><Link to={'/education'}>Humble100 Education</Link></Menu.Item>
                                    //         <Menu.Item key="education:2"><Link to={'/agencyedumaterials'}>Your Agency Education</Link></Menu.Item>
                                    //     </SubMenu>
                                    //     {/* <SubMenu key="Uploads" style={{fontSize: '16px'}} title={<span><UploadOutlined style={{fontSize: '20px'}} /> Uploads</span>}>
                                    //         <Menu.Item key="upload:1"><Link to={'/seriousuploadreport'}>Quote Upload</Link></Menu.Item>
                                    //         <Menu.Item key="upload:2"><Link to={'/newbusinessuploadreport'}>New Business Upload</Link></Menu.Item>
                                    //     </SubMenu> */}
                                    //     <Menu.Item key="7" style={{fontSize: '16px', position: 'absolute', right: 0}}><Link to={'/'} style={{color: 'Red'}}><CloseCircleFilled style={{fontSize: '20px', color: 'Red'}} /> Log Out</Link></Menu.Item>
                                    // </Menu>
                                    // : //Users
                                    // <Menu mode="horizontal" style={{ background: '#fff' }}>
                                    //     <Menu.Item key="1" style={{fontSize: '16px'}}><Link to={'/quotelog'}><FormOutlined style={{fontSize: '20px'}} /> Activity Log</Link></Menu.Item>
                                    //     <SubMenu key="SubMenu" style={{fontSize: '16px'}} title={<span><AreaChartOutlined style={{fontSize: '20px'}} /> Reports</span>}>
                                    //         <Menu.Item key="setting:1"><Link to={'/vendoractivitysummary'}>Lead Source Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:8"><Link to={'/currentcarrier'}>Current Carrier Performance</Link></Menu.Item>
                                    //         <Menu.Item key="sub:9"><Link to={'/referringagentsummary'}>Referring Agent Summary</Link></Menu.Item>
                                    //         <Menu.ItemGroup title="Data Validation">
                                    //             <Menu.Item key="val:1">
                                    //                 <Link to={'/seriousuploadreport'}>Quotes</Link>
                                    //             </Menu.Item>
                                    //             <Menu.Item key="val:2">
                                    //                 <Link to={'/newbusinessuploadreport'}>New Business</Link>
                                    //             </Menu.Item>
                                    //         </Menu.ItemGroup>
                                    //     </SubMenu>
                                    //     <SubMenu key="Education" style={{fontSize: '16px'}} title={<span><BookOutlined style={{fontSize: '20px'}} /> Education</span>}>
                                    //         <Menu.Item key="education:1"><Link to={'/education'}>Humble100 Education</Link></Menu.Item>
                                    //         <Menu.Item key="education:2"><Link to={'/agencyedumaterials'}>Your Agency Education</Link></Menu.Item>
                                    //     </SubMenu>
                                    //     {/* <SubMenu key="Uploads" style={{fontSize: '16px'}} title={<span><UploadOutlined style={{fontSize: '20px'}} /> Uploads</span>}>
                                    //         <Menu.Item key="upload:1"><Link to={'/seriousuploadreport'}>Quote Upload</Link></Menu.Item>
                                    //         <Menu.Item key="upload:2"><Link to={'/newbusinessuploadreport'}>New Business Upload</Link></Menu.Item>
                                    //     </SubMenu> */}
                                    //     <Menu.Item key="2" style={{fontSize: '16px', position: 'absolute', right: 0}}><Link to={'/'} style={{color: 'Red'}}><CloseCircleFilled style={{fontSize: '20px', color: 'Red'}} /> Log Out</Link></Menu.Item>
                                    // </Menu>
                                }
                            </Header>
                            <Content style={{ background: '#fff', minHeight: 280, padding: 20}}>{this.props.children}</Content>
                        </Layout>
                    </Layout>
                    <IdleTimeOutModal 
                        showModal={this.state.showModal}
                        handleClose={this.handleClose}
                        handleLogout={this.handleLogout}
                    />
                    <Modal width={1000}
                        title="Support"
                        visible={this.state.showModalSupport}
                        onOk={this.handleCloseSupport}
                        onCancel={this.handleCloseSupport}
                        footer={[
                        // <Button key="back">
                        //     Submit
                        // </Button>,
                        <Button key="submit" type="primary" onClick={this.handleCloseSupport}>
                            Ok
                        </Button>,
                        ]}>
                        Please send all questions or concerns to <a href="mailto:support@th100consulting.com">support@th100consulting.com</a>
                    </Modal>
                    <Modal width={750}
                        title="The Humble 100 v2.0"
                        visible={this.state.showModalRefresh}
                        onOk={this.handleModalRefresh}
                        onCancel={this.handleModalRefresh}
                        footer={[
                        <Button key="submit" type="primary" onClick={this.handleModalRefresh}>
                            Refresh
                        </Button>,
                        ]}>
                        <strong>The Humble 100 v2.0 is here!!</strong>  Click the Refresh button to get the latest version.
                    </Modal>
                </div>
            );
        }
    }
    export default withRouter(Master);